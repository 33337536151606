
.c-breadcrumbList{
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  margin: 0 0 60px;
  &__item{
    font-size: 12px;
    color: #323232;
    padding: 0 15px 0 0;
    a{
      position: relative;
      color: #787878;
      transition: 0.4s ease;
      &::after{
        content: '>';
        position: absolute;
        top: -4px;
        right: -11px;
        color: #787878;
      }
      &:hover{
        opacity: 0.7;
      }
    }
  }
}

@include sp {


  .c-breadcrumbList{
    // margin: 0 0 20px;
    display: none;
  }
}
