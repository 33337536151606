
.c-table{
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  margin: 0 0 120px;
}
.c-table__item{
  width: 100%;
  &:nth-of-type(1){
    margin: 0 40px 0 0;
  }
  dl{
    line-height: 1.7;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    align-items: center;
    padding: 15px 25px 15px 25px;
    &:nth-of-type(odd){
      background-color: #fbf8f4;
    }
    &:last-of-type{
      margin: 0;
    }
  }
  dt{
    font-weight: bold;
    margin: 0 0 0 0;
    width: 150px;
    min-width: 150px;
  }
}
@include sp {


  .c-table{
    flex-direction: column;
    margin: 0 0 45px;
  }
  .c-table__item{
    width: 100%;
    &:nth-of-type(1){
      margin: 0;
    }
    &:nth-of-type(2){
      dl{

        &:nth-of-type(odd){
          background-color: #fff;
        }
        &:nth-of-type(2n){
          background-color: #fbf8f4;
        }
      }
    }


    dl{
      padding: 15px;
      align-items: flex-start;
      flex-direction: column;
    }
  }
}
