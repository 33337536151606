.c-tabContent {
  &__tabs {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__tab {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-right: 3px;
    height: 50px;
    color: #fff;
    font-size: 18px;
    border-radius: 4px;
    z-index: 3;
    &:last-child {
      margin-right: 0;
    }
    &--primary {
      background-color: $color-primary;
    }
    &--secondary {
      background-color: $color-secondary;
    }
    &--tertiary {
      background-color: $color-tertiary;
    }
    &:before {
      content: '';
      display: block;
      position: absolute;
      bottom: 0px;
      left: 50%;
      width: 18px;
      height: 18px;
      border-radius: 2px;
      transform: rotate(45deg) translate(-50%);
      opacity: 0;
      z-index: -1;
    }
    &.is-active:before {
      opacity: 1;
      bottom: -9px;
      transition: 250ms ease-out;
    }
    &--primary:before {
      background-color: $color-primary;
    }
    &--secondary:before {
      background-color: $color-secondary;
    }
    &--tertiary:before {
      background-color: $color-tertiary;
    }
  }
  &__contents {
    position: relative;
  }
  &__content {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    pointer-events: none;
    padding: 40px;
    width: 100%;
    transition: 250ms ease-out;
    background-color: #fff;
    z-index: 2;
    &.is-active {
      position: relative;
      pointer-events: auto;
      opacity: 1;
      z-index: 1;
    }
  }
}

@include sp {
  .c-tabContent {
    &__tab {
      height: 40px;
      font-size: 15px;
      &:before {
        width: 10px;
        height: 10px;
      }
      &.is-active:before {
        bottom: -7px;
      }
    }
    &__content {
      padding: 15px 20px;
    }
  }
}