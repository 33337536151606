.p-pro__grid{
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  align-items: flex-start;
  margin: 0 0 40px;
  &Left{
    width: 465px;
    min-width: 465px;
  }
  &Right{
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    margin: 0 0 0 50px;
  }
}

.p-pro__text{
  margin: 0 0 35px;
  h2.c-smallTile {
    text-align: left;
  }
  p{
    font-size: 16px;
    color: #323232;
    line-height: 1.7;
  }
}
.p-pro__button{
  width: 320px;
  height: 60px;
  margin: 40px auto 60px;
}
.p-proList__wrap{
  background-color: #fbf8f4;
  border-radius: 10px;
  padding: 30px 50px 20px;
}
.p-proList__title{
  text-align: center;
  font-size: 24px;
  width: 380px;
  margin: auto;
  position: relative;
  &::before{
    position: absolute;
    top: 4px;
    left: 20px;
    width: 2px;
    height: 30px;
    transform: rotate(-20deg);
    background-color: #fa3e3e;
    content: '';
  }
  &::after{
    position: absolute;
    top: 4px;
    right: 20px;
    width: 2px;
    height: 30px;
    transform: rotate(20deg);
    background-color: #fa3e3e;
    content: '';
  }
}
.p-proList{
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  flex-wrap: wrap;
  margin: 30px 0 0;
}
  .p-proList__item{
    width: 23%;
    margin: 0 23px 23px 0;
    &:nth-of-type(4n){
      margin: 0 0 23px;
    }
    img{
      width: 100%;
      max-width: 100%;
      &:hover{
        opacity: 0.8;
      }
    }
  }

 .p-proList__button{
   height: 50px;
   width: 100%;
 }

 .p-proCosme__grid:last-of-type{
   margin: 50px 0 0;
 }
 .p-serNet__buttonWrap{
   text-align: center;
   .p-serNet__button{
     display: block;
     font-size: 18px;
     &:after{
       position: absolute;
       top: 52%;
       right: 20px;
       transform: translateY(-50%);
     }
   }
 }

@include sp {


  .p-pro__grid{
    flex-direction: column;
    &Left{
      width: 100%;
      min-width: auto;
    }
    &Right{
      margin: 0 0 20px;
    }
  }
  .p-pro__image{
    img{
      width: 100%;
      max-width: 100%;
    }
  }
  .p-pro__text{
    p{
      font-size: 15px;
    }
  }
  .p-pro__button{
    width: 80%;
    margin: 40px auto 40px;
  }

  .p-proList{
    flex-wrap: wrap;
  }
  .p-proList__item{
    width: 48%;
    margin: 0 2% 7px 0;
    &:nth-of-type(4n){
      margin: 0 2% 7px 0;
    }
  }

  .p-serNet__button{
    font-size: 17px;
    // height: 50px;
    // text-align: center;
  }
  .p-proList__wrap{
    padding: 20px;
  }
  .p-proList__title{
    width: 290px;
    font-size: 18px;
    &::before{
      top: 1px;
      height: 25px;
    }
    &::after{
      top: 1px;
      height: 25px;
    }
  }
}
