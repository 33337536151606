.p-sectionNews{
  .c-tabContent__content{
    padding: 40px 0;
  }
}



.p-newsDetail__Block{
  width: 800px;
  margin: auto;
}
.p-newsDetail__BlockTitle{
  line-height: 1.5;
  color: #fa3e3e;
  font-weight: bold;
  font-size: 28px;
  margin: 0 0 25px;
  word-wrap: break-word;
  word-break: break-all;
}
.p-newsDetail__info{
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0 0 20px;
}
.p-newsDetail__date{
  font-size: 16px;
  color: #646464;
  margin: 0 20px 0 0;
}

.p-newsDetail__content{
  padding: 40px 0 0;
  border-top: 1px dashed #c8c8c8;
  word-wrap: break-word;
  word-break: break-all;
  h2{
    margin: 45px 0;
    color: #fa3e3e;
    font-weight: bold;
    line-height: 1.5;
    padding: 0 0 0 15px;
    font-size: 20px;
    border-left: 5px dotted #fa3e3e;
  }

  p{
    font-size: 16px;
    line-height: 1.7;
    color: #323232;
  }
  ol{
    list-style: decimal;
    li{
      font-size: 16px;
      line-height: 1.7;
      color: #323232;
      margin: 0 0 0 20px;
      display: list-item;
    }
  }
  .alignright{
    float: right;
    clear: both;
  }
  .alignleft{
    float: left;
    clear: both;
  }
  .aligncenter{
    clear: both;
    text-align: center;
    margin: auto;
    display: block;
  }
  blockquote{
    font-size: 14px;
  }
  img{
    max-width: 800px;
  }

  ul{
    list-style: disc;
    li{
      font-size: 16px;
      line-height: 1.7;
      color: #323232;
      display: list-item;
      margin: 0 0 0 20px;
    }
  }
  strong{
    font-weight: bold;
  }
  em{
    font-style: italic;
  }
  a{
    color: $color-primary;
    transition: 0.4s ease;
    border-bottom: 1px solid $color-primary;
    padding: 0 0 1px;
    &:hover{
      opacity: 0.7;
      border-bottom: 1px solid transparent;
    }
  }
  a.p-newsPdf{
    position: relative;
    display: inline-block;
    color: #fa3e3e;
    font-size: 16px;
    border: 1px solid #fa3e3e;
    border-radius: 6px;
    margin: 50px 0 0;
    padding: 25px 25px 25px 65px;
    transition: 0.4s ease;
    &:hover{
      background-color: #fa3e3e;
      color: #fff;
      &::before{
        background-image: url(../img/pdf_icon02.png);
      }
    }
    &::before{
      content: '';
      position: absolute;
      top: 50%;
      left: 20px;
      width: 32px;
      height: 34px;
      transition: 0.4s ease;
      transform: translateY(-50%);
      background-image: url(../img/pdf_icon01.png);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
    }
  }
}

@include sp {


  .p-sectionNews{
    .c-tabContent__content{
      padding: 15px 0 0;
    }
  }

  .p-newsDetail__Block{
    width: 100%;
  }
  .p-newsDetail__BlockTitle{
    font-size: 22px;
    margin: 0 0 10px;
  }
  .p-newsDetail__info{
    margin: 0 0 15px;
  }
  .p-newsDetail__date{
    font-size: 14px;
  }

  .p-newsDetail__content{
    padding: 25px 0 0;
    h2{
      font-size: 18px;
      margin: 33px 0;
      border-left: 4px dotted #fa3e3e;
    }
    p{
      font-size: 15px;
    }
    img{
      width: 100%;
      max-width: 100%;
      height: 100%;
    }
    a.p-newsPdf{
      padding: 20px 20px 20px 50px;
      font-size: 15px;
      width: 82%;
      display: block;
      margin: 40px auto 0;
      &::before{
        left: 15px;
        width: 25px;
      }
    }
  }




}
