
.p-comp__image{
  margin: 0 auto 40px;
  text-align: center;
}
.p-comp__text{
  width: 800px;
  margin: 0 auto 95px;
  p{
    font-size: 16px;
    color: #323232;
    line-height: 1.7;
  }
}

.p-compTable{
  margin: 0 0 125px;
}
.p-compTable__item{
  width: 50%;
  &:nth-of-type(1){
    margin: 0 40px 0 0;
  }
}

.p-compContact{
  text-align: center;
  width: 800px;
  margin: auto;
  border-radius: 20px;
  padding: 0 0 40px;
  border: 2px solid #fa3e3e;
}
.p-compContact__title{
  position: relative;
  top: -25px;
  width: 580px;
  margin: auto;
  font-size: 24px;
  color: #323232;
  font-weight: bold;
  background-color: #fff;
  &::before{
    content: '';
    position: absolute;
    left: 20px;
    top: 0;
    background-color: #fa3e3e;
    width: 2px;
    height: 60px;
    transform: rotate(-20deg);
  }
  &::after{
    content: '';
    position: absolute;
    right: 20px;
    top: 0;
    background-color: #fa3e3e;
    width: 2px;
    height: 60px;
    transform: rotate(20deg);
  }
}
.p-compContact__tel{
  position: relative;
  margin: -15px 0 0;
  a{
    font-family: "Roboto", sans-serif;
    font-size: 60px;
    font-weight: bold;
    color: #fa3e3e;
    span{
      position: relative;
      right: -7px;
    }
    .c-icon-tel {
      font-size: 55px;
      margin: 0 0 0;
      position: relative;
      top: -5px;
      left: 8px;
      font-weight: bold;
    }
  }
  &::before{
    content: '';
    position: absolute;
    top: 0;
    left: 70px;
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url(../../../company/img/comp_chara01.png);
    width: 61px;
    height: 100px;
  }
  &::after{
    content: '';
    position: absolute;
    top: 0;
    right: 70px;
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url(../../../company/img/comp_chara02.png);
    width: 61px;
    height: 100px;
  }
}
.p-compContact__text{
    margin: -15px 0 0;
  p{
    text-align: center;
    color: #505050;
    font-size: 14px;
  }
}

@include sp {

  .p-comp__image{
    margin: 0 0 20px;
    img{
      width: 100%;
      max-width: 100%;
    }
  }
  .p-comp__text{
    margin: 0 0 25px;
    width: 100%;
    p{
      font-size: 15px;
    }
  }
  .p-compTable{
    margin: 0 0 35px;
  }
  .p-compTable__item{
    width: 100%;
    &:nth-of-type(1){
      margin: 0;
    }
    &:nth-of-type(2){
      dl{
        &:nth-of-type(odd){
          background-color: #fff;
        }
        &:nth-of-type(even){
          background-color: #fbf8f4;
        }
      }
    }

  }
  .p-compContact{
    width: 100%;
    padding: 0;
    border: none;
  }
  .p-compContact__title{
    position: relative;
    top: auto;
    width: auto;
    font-size: 17px;
    margin: 0 auto 10px;

    &::before{
      height: 70px;
      transform: rotate(-27deg);
    }
    &::after{
      height: 70px;
      transform: rotate(27deg);
    }
  }
  .p-compContact__tel{

    margin: 0 auto;
    img{
      width: 100%;
      max-width: 100%;
    }
    &::before{
      display: none;
    }
    &::after{
      display: none;
    }
    a{
      width: 100%;
      height: auto;
      padding: 13px 0 30px;
      display: block;
      border-radius: 6px;
      background-color: #fa3e3e;
      font-size: 29px;
      color: #fff;
      span{
        display: inline-block;
        margin: 0 0 8px;
        right: -4px;
      }
      .c-icon-tel{
        font-size: 29px;
        left: 4px;
        top: -5px;
      }
    }
  }
  .p-compContact__text{
    margin: -37px 0 0;
    p{
      color: #fff;
      font-size: 12px;
    }
  }






}
