.c-commonGrid__first{
  margin: 0 0 50px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  align-items: flex-start;
  &Left{
    flex: 1;
  }
  &Right{
    width: 470px;
    min-width: 470px;
    margin: 0 0 0 50px;
  }
  .c-basicButton{
    margin: 0;
    width: 320px;
    height: 60px;
  }
  & + .c-smallTile{
    margin: 50px 0 20px;
  }
}
.c-commonGrid__text{
  margin: 0 0 35px;
  .c-smallTile{
    text-align: left;
  }
  p{
    color: #323232;
    font-size: 16px;
    line-height: 1.7;
  }
}
.c-commonGrid__first:last-of-type{
  margin: 0;
}




.c-commonGrid__second{
  margin: 0 0 50px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  align-items: flex-start;
  &Left{
    width: 470px;
    min-width: 470px;
    margin: 0 50px 0 0;
  }
  &Right{
    flex: 1;
  }
  .c-basicButton{
    margin: 0;
    width: 320px;
    height: 60px;
  }
  & + .c-smallTile{
    margin: 50px 0 20px;
  }
}
.c-commonGrid__second:last-of-type{
  margin: 0;
}


.c-commonGrid__image{
  margin: 0 auto 20px;
}
.c-commonGrid__text{
  margin: 0 0 35px;
  .c-smallTile{
    text-align: left;
  }
  p{
    color: #323232;
    font-size: 16px;
    line-height: 1.7;
  }
}

@include sp {
  .c-commonGrid__first{
    flex-direction: column;
    &Left{
      order: 2;
      flex: 1;
    }
    &Right{
      order: 1;
      width: 100%;
      min-width: auto;
      margin: 0 0 0;
    }
    .c-basicButton{
      width: 87%;
      height: 50px;
    }
    & + .c-smallTile{
      margin: 20px 0 0;
    }
  }
  .c-commonGrid__first:last-of-type{
    .c-commonGrid__text{
      margin: 0;
    }
    .c-basicButton{
      margin: 20px 0 20px;
    }
  }
  .c-commonGrid__second{
    flex-direction: column;
    &Left{
      order: 1;
      width: 100%;
      min-width: auto;
      margin: 0 0 0;
    }
    &Right{
      order: 2;
      flex: 1;
    }
    .c-basicButton{
      width: 87%;
      height: 50px;
    }
    & + .c-smallTile{
      margin: 20px 0 20px;
    }
  }
  .c-commonGrid__text{
    p{
      font-size: 15px;
    }
  }
  .c-commonGrid__image{
    img{
      width: 100%;
      max-width: 100%;
    }
  }
  .c-commonGrid__second:last-of-type{
    .c-commonGrid__text{
      margin: 0;
    }
    .c-basicButton{
      margin: 20px 0 0;
    }
  }

}
