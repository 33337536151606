.c-header {
  border-top: 6px solid $color-primary;
  background-color: #fff;
  box-shadow: 0 1px 0 0 #eee;
  min-width: 1315px;
  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 11px 44px 0px 27px;
  }
  &__logo {
    width: 189px;
    margin-bottom: 8px;
    img{
      width: 100%;
      max-width: 100%;
    }
  }
  &__left {
    display: flex;
    align-items: center;
  }
  &__company {
    text-align: center;
    font-size: 24px;
    line-height: 1;
    margin: -10px 0 0 30px;
    font-weight: bold;
  }
  &__linksWrap {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 15px;
  }
  &__links {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-right: 1px solid #c8c8c8;
    padding: 0 20px;
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
      border-right: 0;
    }
  }
  &__link {
    margin-right: 15px;
    &:last-child {
      margin-right: 0;
    }
  }
  &__tel {
    a {
      font-family: $font-en;
      font-size: 28px;
      font-weight: bold;
      color: $color-primary;
      .c-icon-tel {
        margin-right: 10px;
        font-weight: bold;
      }
    }
  }
  &__navList {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  &__navItem {
    position: relative;
    font-size: 18px;
    font-weight: 700;
    height: 40px;
    color: $color-black;
    &--hover {
      padding: 0 23px;
    }
    & > a {
      display: block;
      position: relative;
      padding: 0 23px;
      font-size: 18px;
      font-weight: 700;
      height: 40px;
      color: $color-black;
      &:after {
        content: "";
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 4px;
        background-color: $color-primary;
        opacity: 0;
        transform: translateY(100%);
        transition: 200ms ease-out;
      }
      &:hover:after {
        transform: translateY(0);
        opacity: 1;
      }
    }
    &:last-child {
      a {
        padding-right: 15px;
      }
    }
  }
  &__pulldown {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%) translateY(-10%);
    z-index: 5;
    background-color: $color-primary;
    min-width: 180px;
    opacity: 0;
    transition: 250ms ease-out;
    border-radius: 6px;
    pointer-events: none;
    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 50%;
      width: 10px;
      height: 10px;
      transform: translate(0, -50%) rotate(45deg);
      background-color: $color-primary;
      border-radius: 2px;
      z-index: -1;
    }
  }
  &__navItem:hover &__pulldown {
    pointer-events: auto;
    opacity: 1;
    transform: translateX(-50%) translateY(0);
  }
  &__pulldownItem {
    display: flex;
    padding: 10px 15px;
    transition: 100ms ease-out;
    color: #fff;
    font-size: 16px;
    font-weight: normal;
    border-bottom: 1px solid #ff6e6e;
    &:before {
      content: "";
      display: inline-block;
      margin-right: 10px;
      width: 6px;
      height: 24px;
      background-image: url('../img/button_icon04.svg');
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
      vertical-align: bottom;
    }
    &:last-child {
      border-bottom: none;
    }
    &:hover {
      background-color: rgba(200, 200, 200, 0.2);
    }
  }
  &__menuButton {
    display: none;
  }
}

@include sp {
  .c-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    border-top-width: 5px;
    z-index: 30;
    min-width: auto;
    &__inner {
      padding: 0 10px;
      height: 50px;
    }
    &__left {
      display: flex;
      align-items: center;
    }
    &__logo {
      width: 83px;
      min-width: 83px;
      margin: 1px 13px 0 3px;
      img {
        width: 100%;
      }
    }
    &__company {
      margin: 0;
      font-size: 14px;
    }
    &__right {
      display: flex;
      flex-direction: column;
    }
    &__menu {
      opacity: 0;
      pointer-events: none;
      position: fixed;
      top: 50px;
      left: 0;
      width: 100%;
      height: calc(100% - 50px);
      background-color: rgba(0,0,0,0.2);
      overflow: auto;
      z-index: 100;
      transition: 250ms ease-out;
      &.is-active {
        pointer-events: auto;
        opacity: 1;
      }
    }
    &__menu.is-active &__linksWrap {
      transform: translate(0);
    }
    &__linksWrap {
      flex: 1;
      order: 2;
      display: flex;
      flex-direction: column-reverse;
      margin: 0 0 0 auto;
      padding: 30px 20px;
      width: 300px;
      min-height: 350px;
      background-color: #fff;
      transform: translate(100%);
      transition: 250ms ease-out;
    }
    &__links {
      justify-content: center;
      margin-bottom: 25px;
      padding: 0;
      width: 100%;
      border-right: none;
    }
    &__link {
      margin-right: 22px;
    }
    &__snsLink {
      width: 35px;
      img {
        width: 100%;
      }
    }
    &__tel {
      width: 100%;
      a {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 60px;
        background-color: $color-primary;
        border-radius: 6px;
        color: #fff;
        font-size: 24px;
        .c-icon-tel {
          font-weight: normal;
        }
      }
      p {
        margin-top: 5px;
        font-size: 12px;
        color: #646464;
        text-align: center;
      }
    }
    &__menu.is-active &__nav {
      transform: translate(0);
    }
    &__nav {
      order: 1;
      margin: 0 0 0 auto;
      width: 300px;
      background-color: #fff;
      transform: translate(100%);
      transition: 250ms ease-out;
    }
    &__navList {
      display: block;
    }
    &__navItem {
      border-bottom: 1px solid #e6e6e6;
      height: auto;
      &--hover {
        padding: 0;
      }
      &--hover > span,
      & > a {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 45px;
        background-color: #fff;
        font-size: 15px;
      }
      &--hover > span {
        position: relative;
        padding: 0 23px;
        &:before,
        &:after {
          content: "";
          display: block;
          position: absolute;
          top: 50%;
          right: 23px;
          width: 15px;
          height: 1px;
          background-color: $color-primary;
          transition: 300ms ease-out;
        }
        &:after {
          transform: rotate(90deg);
        }
      }
      &--hover.is-active > span {
        &:after {
          transform: rotate(0);
        }
      }
      & > a {
        &:after {
          content: "";
          position: static;
          bottom: auto;
          left: auto;
          display: inline-block;
          transform: none;
          width: 15px;
          height: 15px;
          background-image: url('../img/button_icon01.svg');
          background-repeat: no-repeat;
          background-position: center center;
          background-size: contain;
          background-color: transparent;
          opacity: 1;
        }
      }
      &:last-child > a {
        padding-right: 23px;
      }
    }
    &__pulldown {
      display: none;
      background-color: #f5f5f5;
      border-radius: 0;
      transition: none;
      pointer-events: auto;
      &:before {
        content: none;
      }
    }
    &__pulldown,
    &__navItem:hover &__pulldown {
      position: relative;
      top: 0;
      left: 0;
      width: 100%;
      opacity: 1;
      pointer-events: auto;
      transform: none;
    }
    &__pulldownItem {
      margin: 0 20px;
      padding-left: 0;
      padding-right: 0;
      color: #505050;
      border-bottom: 1px solid #e6e6e6;
      &:before {
        background-image: url('../img/button_icon03.svg');
      }
    }
    &__menuButton {
      display: block;
      position: relative;
      width: 26px;
      height: 20px;
      div {
        display: block;
        position: absolute;
        width: 26px;
        height: 3px;
        border-radius: 1.5px;
        background-color: $color-primary;
        transition: 250ms ease-out;
        &:nth-child(1) {
          top: 0;
          left: 0;
        }
        &:nth-child(2) {
          top: calc(50% - 1px);
          left: 0;
          transform-origin: left center;
        }
        &:nth-child(3) {
          top: calc(100% - 1px);
          left: 0;
        }
      }
      &.is-active div {
        &:nth-child(1) {
          top: calc(50% - 1px);
          transform: rotate(45deg);
        }
        &:nth-child(2) {
          transform: rotateY(90deg);
          opacity: 0;
        }
        &:nth-child(3) {
          top: calc(50% - 1px);
          transform: rotate(-45deg);
        }
      }
    }
  }
}
