.c-fixedBanner {
  position: fixed;
  right: 15px;
  bottom: 140px;
  z-index: 20;
  width: 255px;
  a {
    img {
      width: 100%;
      max-width: 100%;
      transition: 250ms ease-out;
    }
  }
  a:hover {
    img {
      transform: scale(1.1);
    }
  }
}

@include sp {
  .c-fixedBanner {
    bottom: 20px;
    width: auto;
      a{
        img{
          width: 128px;
          max-width: none;
        }
      }
  }
}
