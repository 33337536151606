@import '_variable';

$content-width: 980px;
$content-padding: 60px;
$sp-content-padding: 20px;
.l-wrapper {
  min-width: $content-width + ($content-padding * 2);
  overflow-x: hidden;
}
.l-container {
  margin-left: auto;
  margin-right: auto;
  padding: 0 $content-padding;
  width: $content-width + ($content-padding * 2);
}

@include sp  {
  .l-wrapper {
    min-width: 0;
    width: 100%;
    padding-top: 50px;
  }
  .l-container {
    padding: 0 $sp-content-padding;
    width: 100%;
  }
}