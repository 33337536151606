.c-sliderPagination {
  bottom: -30px;
  width: 100%;
  text-align: center;
  .swiper-pagination-bullet {
    margin-right: 10px;
    width: 10px;
    height: 10px;
    background-color: #adadad;
    &:last-child {
      margin-right: 0;
    }
  }
  .swiper-pagination-bullet-active {
    background-color: $color-primary;
  }
}

.c-sliderPrev01,
.c-sliderNext01 {
  position: absolute;
  width: 60px;
  min-width: 60px;
  height: 60px;
  background-size: contain;
  transition: 100ms ease-out;
  &:hover {
    opacity: 0.7;
  }
}
.c-sliderPrev01 {
  background-image: url('../img/prev01.png');
}
.c-sliderNext01 {
  background-image: url('../img/next01.png');
}

.c-sliderPrev02,
.c-sliderNext02 {
  position: absolute;
  width: 40px;
  min-width: 40px;
  height: 40px;
  background-size: contain;
  transition: 100ms ease-out;
  &:hover {
    opacity: 0.7;
  }
}
.c-sliderPrev02 {
  left: -70px;
  background-image: url('../img/prev02.png');
}
.c-sliderNext02 {
  right: -70px;
  background-image: url('../img/next02.png');
}

@include sp {
  .c-sliderPagination {
    .swiper-pagination-bullet {
      margin-right: 5px;
      width: 8px;
      height: 8px;
    }
  }
  .c-sliderPrev01,
  .c-sliderNext01 {
    width: 30px;
    height: 30px;
  }
  .c-sliderPrev02,
  .c-sliderNext02 {
    top: 30px;
    width: 30px;
    height: 30px;
  }
  .c-sliderPrev02 {
    left: -10px;
  }
  .c-sliderNext02 {
    right: -10px;
  }
}
