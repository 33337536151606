.c-largeTitle {
  text-align: center;
  &--marginMedium {
    margin-bottom: 50px;
  }
  &--marginSmall {
    margin-bottom: 30px;
  }
  &--left {
    text-align: left;
  }
  &__icon {
    display: block;
    margin-bottom: 8px;
    font-size: 40px;
    color: $color-primary;
    line-height: 1;
    &Image{
      width: 69px;
      margin: auto;
      img{
        width: 100%;
        max-width: 100%;
      }
    }
  }
  &__main {
    font-size: 44px;
    font-weight: 500;
    display: block;
    &--ja {
      font-family: 'M PLUS Rounded 1c', sans-serif;
      display: block;
    }
  }
  &__sub {
    display: block;
    margin-top: 8px;
    color: $color-primary;
    font-size: 16px;
    font-weight: bold;
    line-height: 1;
    &--en {
      display: block;
      font-family: 'M PLUS Rounded 1c', sans-serif;
    }
  }
}
.c-middleTitle{
  position: relative;
  text-align: center;
  font-size: 34px;
  margin: 0 0 50px;
  padding: 40px 0 20px;
  color: #464646;
  font-weight: bold;
  font-family: 'M PLUS Rounded 1c', sans-serif;
  z-index: 3;
  &::before{
    content: '';
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    background-size: auto;
    background-repeat: repeat-x;
    background-position: left center;
    background-image: url(../img/title_dot.png);
    width: 44px;
    height: 3px;
    z-index: 2;
  }
  &::after{
    content: '';
    position: absolute;
    top: 0px;
    left: 50%;
    transform: translateX(-50%);
    background-size: auto;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url(../img/title_yakult.png);
    width: 90px;
    height: 140px;
    z-index: -1;
  }
}
.c-middleTitle--noimg{
      padding: 0 0 20px;
    &::after{
      display: none;
    }
}
.c-middleTitle--large{
  &::after{
    width: 140px;
    height: 185px;
    background-size: contain;
  }
}

.c-smallTile{
  line-height: 1.5;
  margin: 0 0 20px;
  text-align: center;
  font-weight: bold;
  font-size: 28px;
  color: #fa3e3e;
}





.c-commonTitle__section{
  padding: 60px 70px;
  height: 260px;
  background-image: url(../img/common_title_bg.jpg);
  background-size: cover;
  background-position: center top;
  background-repeat: no-repeat;
}







@include sp {
  .c-largeTitle {
    &--marginMedium {
      margin-bottom: 30px;
    }
    &__icon {
      margin-bottom: 10px;
      font-size: 32px;
      &Image{
        width: 52px;
        margin: 0 auto 10px;
      }
    }
    &__main {
      font-size: 23px;
      font-weight: 500;
      letter-spacing: 0.08em;
      line-height: 1;
      &--ja{
        // font-size: 16px;
      }
    }

    &__sub {
      margin-top: 10px;
      font-size: 14px;
    }
  }


  .c-middleTitle{
    font-size: 25px;
    margin: 0 0 30px;
    padding: 0 0 10px;
  }
  .c-middleTitle::after{
    top: -50px;
    width: 60px;
    background-size: contain;
  }

  .c-smallTile{
    font-size: 22px;
    margin: 0 0 15px;
  }

  .c-commonTitle__section{
    padding: 32px 0 0;
    height: 160px;
    background-image: url(../img/common_title_bg_sp.jpg);
  }


}
