.p-sectionBusiness{
  padding: 70px 0 100px;
  background-color: #fbf8f4;
}


.p-busi__text{
  width: 800px;
  margin: 0 auto 45px;
  p{
    line-height: 1.7;
    color: #323232;
    font-size: 16px;
  }
}
.p-busiList{
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  align-items: flex-start;
  .swiper-wrapper{
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
  }
  .swiper-slide{
    width: auto;
  }
  .c-sliderNext01,
  .c-sliderPrev01{
    display: none;
  }
}
.p-busiList__item{
  text-align: center;
  margin: 0 40px 0 0;
  &:last-of-type{
    margin: 0;
  }
  a{
    display: block;
    transition: 0.4s ease;.p-busiShirota__button
    &:hover{
      opacity: 0.7;
    }
  }
}
.p-busiList__image{
  margin: 0 auto 20px;
}
.p-busiList__text{
  p{
    font-size: 20px;
    font-weight: bold;
    color: #fa3e3e;
  }
}




.p-busiAbout__grid{
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  align-items: flex-start;
  &Left{
    width: 470px;
    min-width: 470px;
    margin: 0 40px 0 0;
  }
}
.p-busiAbout__text{
  p{
    font-size: 16px;
    line-height: 1.7;
    color: #323232;
  }
}

.p-sectionBusiShirota{
  padding: 50px 0 85px;
  background-color: #fbf8f4;
  .c-middleTitle{
    margin: 0 auto 25px;
  }
}
.p-busiShirota__read{
  margin: 0 auto 40px;
  p{
    text-align: center;
    line-height: 1.7;
    font-size: 16px;
    color: #323232;
  }
}
.p-busiShirota__button{
  width: 360px;
  margin: auto;
  height: 60px;
  font-size: 18px;
}

.p-sectionBusiAbout{
  padding: 0px 0 100px;
}

@include sp {
  .p-busi__text{
    width: auto;
    margin: 0 0 20px;
    p{
      font-size: 15px;
    }
  }
  .p-busiList{
    .swiper-container{
      padding: 0 0 40px;
    }
    .swiper-slide{
      // width: 100%;
    }
    .swiper-button-next,
    .swiper-button-prev{
      top: 40%;
    }
    .swiper-button-next{
      right: -10px;
    }
    .swiper-button-prev{
      left: -10px;
    }
    .c-sliderNext01,
    .c-sliderPrev01{
      display: block;
    }
  }

  .p-busiAbout__grid{
    flex-direction: column;
    &Left{
      width: 100%;
      min-width: auto;
    }
  }
  .p-busiAbout__image{
    margin: 0 0 20px;
    img{
      width: 100%;
      max-width: 100%;
    }
  }
  .p-busiAbout__text{
    p{
      font-size: 15px;
    }
  }
  .p-busiShirota__read{
    p{
      font-size: 15px;
    }
  }

  .p-busiShirota__button{
    width: 100%;
    height: 50px;
    font-size: 17px;
  }

  .p-busiShirota__button{
    font-size: 17px;
    height: 50px;
  }

  .p-sectionBusiAbout{
    padding: 0px 0 60px;
  }

}
