
@include sp {
  .js-accordion{
    position: relative;
  }
  .js-accordion::before {
      content: "";
      width: 2px;
      height: 16px;
      background: #fa3e3e;
      position: absolute;
      right: 22px;
      top: 0;
      bottom: 0;
      margin: auto;
      -webkit-transition: .6s ease;
      -o-transition: .6s ease;
      transition: .6s ease;
  }
  .js-accordion::after {
      content: "";
      width: 16px;
      height: 2px;
      background: #fa3e3e;
      position: absolute;
      right: 15px;
      top: 0;
      bottom: 0;
      margin: auto;
      -webkit-transition: .6s ease;
      -o-transition: .6s ease;
      transition: .6s ease;
  }
  .js-accordion.is-accordion--open::before {
      transform: rotate(90deg);
  }
  .js-accordion.is-accordion--open::after {
      transform: rotate(-180deg);
  }
}
