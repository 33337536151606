@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c:wght@300;400;500;700&display=swap");
@font-face {
  font-family: 'fontello';
  src: url("../font/fontello.eot?72537327");
  src: url("../font/fontello.eot?72537327#iefix") format("embedded-opentype"), url("../font/fontello.woff?72537327") format("woff"), url("../font/fontello.ttf?72537327") format("truetype"), url("../font/fontello.svg?72537327#fontello") format("svg");
  font-weight: normal;
  font-style: normal;
}

/* -- RESET
--------------------------------------------- */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

li {
  display: block;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

button,
input {
  appearance: none;
  padding: 0;
  border: none;
  outline: none;
  background: transparent;
  background: none;
}

/* -- BASE
--------------------------------------------- */
*, *:after, *:before {
  box-sizing: border-box;
}

body, input, textarea, select, button {
  font-family: "ヒラギノ角ゴシック", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", Roboto, "Droid Sans", YuGothic, "游ゴシック", Meiryo, "メイリオ", Verdana, "ＭＳ Ｐゴシック", sans-serif;
  font-weight: 500;
  -webkit-appearance: none;
  color: #323232;
  font-size: 16px;
  line-height: 1.5;
  -webkit-text-size-adjust: 100%;
}

body {
  overflow-x: hidden;
}

a {
  color: #323232;
  text-decoration: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

a:hover {
  text-decoration: none;
}

img {
  vertical-align: bottom;
}

img[usemap] {
  height: auto;
}

hr {
  height: 1px;
  background-color: #DFE3E6;
  border: none;
  color: #DFE3E6;
}

template {
  position: absolute;
  top: -99999px;
  left: -99999px;
  opacity: 0;
  pointer-events: none;
}

input[type="text"] {
  padding: 10px;
  border: 1px solid #d2d2d2;
  font-size: 15px;
  line-height: 1.75;
  background-color: #fff;
  border-radius: 6px;
}

input::placeholder {
  color: #c8c8c8;
}

textarea {
  padding: 10px;
  font-size: 15px;
  line-height: 1.8;
  border: 2px solid #d2d2d2;
  background-color: #fff;
}

textarea:focus {
  outline: none;
  border-color: #0162b5;
}

ruby[data-ruby] {
  position: relative;
}

ruby[data-ruby]::before {
  content: attr(data-ruby);
  position: absolute;
  line-height: 100%;
  text-align: center;
  left: -3em;
  right: -3em;
  transform-origin: bottom center;
  transform: scale(0.5);
  bottom: 100%;
}

ruby[data-ruby] rt {
  display: none;
}

i {
  font-family: 'fontello';
}

@media screen and (max-width: 768px) {
  body, input, textarea, select, button {
    font-size: 15px;
    line-height: 1.5;
  }
  img {
    width: 100%;
    max-width: 100%;
    height: auto;
  }
}

@media screen and (min-width: 769px) {
  .u-only-sp {
    display: none !important;
  }
}

@media screen and (max-width: 768px) {
  .u-only-pc {
    display: none !important;
  }
  .u-order-one {
    order: 1;
  }
  .u-order-two {
    order: 2;
  }
  .u-order-three {
    order: 3;
  }
  .u-order-four {
    order: 4;
  }
}

.l-wrapper {
  min-width: 1100px;
  overflow-x: hidden;
}

.l-container {
  margin-left: auto;
  margin-right: auto;
  padding: 0 60px;
  width: 1100px;
}

@media screen and (max-width: 768px) {
  .l-wrapper {
    min-width: 0;
    width: 100%;
    padding-top: 50px;
  }
  .l-container {
    padding: 0 20px;
    width: 100%;
  }
}

.c-icon-bottle {
  position: relative;
  display: inline-block;
  font-size: 1em;
  font-weight: normal;
  line-height: inherit;
}

.c-icon-bottle:after {
  content: '\E801';
  display: inline-block;
  font-family: 'fontello';
}

.c-icon-handshake {
  position: relative;
  display: inline-block;
  font-size: 1em;
  font-weight: normal;
  line-height: inherit;
}

.c-icon-handshake:after {
  content: '\E803';
  display: inline-block;
  font-family: 'fontello';
}

.c-icon-tel {
  position: relative;
  display: inline-block;
  font-size: 1em;
  font-weight: normal;
  line-height: inherit;
}

.c-icon-tel:after {
  content: '\E80B';
  font-family: 'fontello';
  display: inline-block;
}

.c-icon-tel2 {
  position: relative;
  display: inline-block;
  font-size: 1em;
  font-weight: normal;
  line-height: inherit;
}

.c-icon-tel2:after {
  content: '\E802';
  font-family: 'fontello';
  display: inline-block;
}

.c-icon-building {
  position: relative;
  display: inline-block;
  font-size: 1em;
  font-weight: normal;
  line-height: inherit;
}

.c-icon-building:after {
  content: '\E804';
  font-family: 'fontello';
  display: inline-block;
}

.c-icon-comment {
  position: relative;
  display: inline-block;
  font-size: 1em;
  font-weight: normal;
  line-height: inherit;
}

.c-icon-comment:after {
  content: '\E805';
  font-family: 'fontello';
  display: inline-block;
}

.c-icon-mail {
  position: relative;
  display: inline-block;
  font-size: 1em;
  font-weight: normal;
  line-height: inherit;
}

.c-icon-mail:after {
  content: '\E807';
  font-family: 'fontello';
  display: inline-block;
}

.c-textButton {
  display: inline-flex;
  align-items: center;
  font-size: 16px;
  color: #fa3e3e;
  vertical-align: middle;
  font-weight: 500;
  padding: 4px;
  border-radius: 4px;
  transition: 100ms ease-out;
}

.c-textButton:hover {
  background-color: rgba(250, 62, 62, 0.1);
}

.c-textButton:after {
  content: '';
  display: inline-block;
  margin-left: 8px;
  width: 16px;
  height: 16px;
  background-image: url("../img/button_icon01.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
}

.c-basicButton {
  display: flex;
  align-items: center;
  position: relative;
  padding: 20px;
  color: #fff;
  margin: auto;
  width: 360px;
  height: 60px;
  border-radius: 10px;
  background-color: #fa3e3e;
  font-weight: 700;
  transition: 100ms ease-out;
}

.c-basicButton:hover {
  opacity: 0.8;
}

.c-basicButton:after {
  content: "";
  display: block;
  margin-left: auto;
  margin-right: 0;
  width: 16px;
  height: 16px;
  background-image: url("../img/button_icon02.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
}

.c-basicButton--blank:after {
  content: "";
  display: block;
  margin-left: auto;
  margin-right: 0;
  width: 18px;
  height: 18px;
  background-image: url("../img/button_icon03.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
}

@media screen and (max-width: 768px) {
  .c-textButton {
    font-size: 14px;
  }
  .c-textButton:after {
    width: 15px;
    height: 15px;
  }
  .c-basicButton {
    width: 100%;
    font-size: 15px;
    border-radius: 6px;
  }
}

.c-section {
  padding: 90px 0 100px;
}

.c-section--pt1 {
  padding: 100px 0 100px;
}

.c-section--pt2 {
  padding: 120px 0 100px;
}

.c-section--pt3 {
  padding: 60px 0;
}

.c-sectionUnder {
  padding: 20px 0 100px;
}

.c-sectionMilky {
  background-color: #fbf8f4;
}

.c-commonRead {
  margin: 0 0 50px;
}

.c-commonRead p {
  text-align: center;
  z-index: 16;
  line-height: 1.7;
  color: #323232;
}

@media screen and (max-width: 768px) {
  .c-section {
    padding: 60px 0;
  }
  .c-section--pt2 {
    padding: 60px 0;
  }
  .c-commonRead {
    margin: 0 0 30px;
  }
  .c-commonRead p {
    font-size: 15px;
  }
  .c-sectionUnder {
    padding: 20px 0 60px;
  }
}

.c-sliderPagination {
  bottom: -30px;
  width: 100%;
  text-align: center;
}

.c-sliderPagination .swiper-pagination-bullet {
  margin-right: 10px;
  width: 10px;
  height: 10px;
  background-color: #adadad;
}

.c-sliderPagination .swiper-pagination-bullet:last-child {
  margin-right: 0;
}

.c-sliderPagination .swiper-pagination-bullet-active {
  background-color: #fa3e3e;
}

.c-sliderPrev01,
.c-sliderNext01 {
  position: absolute;
  width: 60px;
  min-width: 60px;
  height: 60px;
  background-size: contain;
  transition: 100ms ease-out;
}

.c-sliderPrev01:hover,
.c-sliderNext01:hover {
  opacity: 0.7;
}

.c-sliderPrev01 {
  background-image: url("../img/prev01.png");
}

.c-sliderNext01 {
  background-image: url("../img/next01.png");
}

.c-sliderPrev02,
.c-sliderNext02 {
  position: absolute;
  width: 40px;
  min-width: 40px;
  height: 40px;
  background-size: contain;
  transition: 100ms ease-out;
}

.c-sliderPrev02:hover,
.c-sliderNext02:hover {
  opacity: 0.7;
}

.c-sliderPrev02 {
  left: -70px;
  background-image: url("../img/prev02.png");
}

.c-sliderNext02 {
  right: -70px;
  background-image: url("../img/next02.png");
}

@media screen and (max-width: 768px) {
  .c-sliderPagination .swiper-pagination-bullet {
    margin-right: 5px;
    width: 8px;
    height: 8px;
  }
  .c-sliderPrev01,
  .c-sliderNext01 {
    width: 30px;
    height: 30px;
  }
  .c-sliderPrev02,
  .c-sliderNext02 {
    top: 30px;
    width: 30px;
    height: 30px;
  }
  .c-sliderPrev02 {
    left: -10px;
  }
  .c-sliderNext02 {
    right: -10px;
  }
}

.c-largeTitle {
  text-align: center;
}

.c-largeTitle--marginMedium {
  margin-bottom: 50px;
}

.c-largeTitle--marginSmall {
  margin-bottom: 30px;
}

.c-largeTitle--left {
  text-align: left;
}

.c-largeTitle__icon {
  display: block;
  margin-bottom: 8px;
  font-size: 40px;
  color: #fa3e3e;
  line-height: 1;
}

.c-largeTitle__iconImage {
  width: 69px;
  margin: auto;
}

.c-largeTitle__iconImage img {
  width: 100%;
  max-width: 100%;
}

.c-largeTitle__main {
  font-size: 44px;
  font-weight: 500;
  display: block;
}

.c-largeTitle__main--ja {
  font-family: 'M PLUS Rounded 1c', sans-serif;
  display: block;
}

.c-largeTitle__sub {
  display: block;
  margin-top: 8px;
  color: #fa3e3e;
  font-size: 16px;
  font-weight: bold;
  line-height: 1;
}

.c-largeTitle__sub--en {
  display: block;
  font-family: 'M PLUS Rounded 1c', sans-serif;
}

.c-middleTitle {
  position: relative;
  text-align: center;
  font-size: 34px;
  margin: 0 0 50px;
  padding: 40px 0 20px;
  color: #464646;
  font-weight: bold;
  font-family: 'M PLUS Rounded 1c', sans-serif;
  z-index: 3;
}

.c-middleTitle::before {
  content: '';
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  background-size: auto;
  background-repeat: repeat-x;
  background-position: left center;
  background-image: url(../img/title_dot.png);
  width: 44px;
  height: 3px;
  z-index: 2;
}

.c-middleTitle::after {
  content: '';
  position: absolute;
  top: 0px;
  left: 50%;
  transform: translateX(-50%);
  background-size: auto;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url(../img/title_yakult.png);
  width: 90px;
  height: 140px;
  z-index: -1;
}

.c-middleTitle--noimg {
  padding: 0 0 20px;
}

.c-middleTitle--noimg::after {
  display: none;
}

.c-middleTitle--large::after {
  width: 140px;
  height: 185px;
  background-size: contain;
}

.c-smallTile {
  line-height: 1.5;
  margin: 0 0 20px;
  text-align: center;
  font-weight: bold;
  font-size: 28px;
  color: #fa3e3e;
}

.c-commonTitle__section {
  padding: 60px 70px;
  height: 260px;
  background-image: url(../img/common_title_bg.jpg);
  background-size: cover;
  background-position: center top;
  background-repeat: no-repeat;
}

@media screen and (max-width: 768px) {
  .c-largeTitle--marginMedium {
    margin-bottom: 30px;
  }
  .c-largeTitle__icon {
    margin-bottom: 10px;
    font-size: 32px;
  }
  .c-largeTitle__iconImage {
    width: 52px;
    margin: 0 auto 10px;
  }
  .c-largeTitle__main {
    font-size: 23px;
    font-weight: 500;
    letter-spacing: 0.08em;
    line-height: 1;
  }
  .c-largeTitle__sub {
    margin-top: 10px;
    font-size: 14px;
  }
  .c-middleTitle {
    font-size: 25px;
    margin: 0 0 30px;
    padding: 0 0 10px;
  }
  .c-middleTitle::after {
    top: -50px;
    width: 60px;
    background-size: contain;
  }
  .c-smallTile {
    font-size: 22px;
    margin: 0 0 15px;
  }
  .c-commonTitle__section {
    padding: 32px 0 0;
    height: 160px;
    background-image: url(../img/common_title_bg_sp.jpg);
  }
}

.c-header {
  border-top: 6px solid #fa3e3e;
  background-color: #fff;
  box-shadow: 0 1px 0 0 #eee;
  min-width: 1315px;
}

.c-header__inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 11px 44px 0px 27px;
}

.c-header__logo {
  width: 189px;
  margin-bottom: 8px;
}

.c-header__logo img {
  width: 100%;
  max-width: 100%;
}

.c-header__left {
  display: flex;
  align-items: center;
}

.c-header__company {
  text-align: center;
  font-size: 24px;
  line-height: 1;
  margin: -10px 0 0 30px;
  font-weight: bold;
}

.c-header__linksWrap {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 15px;
}

.c-header__links {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-right: 1px solid #c8c8c8;
  padding: 0 20px;
}

.c-header__links:first-child {
  padding-left: 0;
}

.c-header__links:last-child {
  padding-right: 0;
  border-right: 0;
}

.c-header__link {
  margin-right: 15px;
}

.c-header__link:last-child {
  margin-right: 0;
}

.c-header__tel a {
  font-family: "Roboto", sans-serif;
  font-size: 28px;
  font-weight: bold;
  color: #fa3e3e;
}

.c-header__tel a .c-icon-tel {
  margin-right: 10px;
  font-weight: bold;
}

.c-header__navList {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.c-header__navItem {
  position: relative;
  font-size: 18px;
  font-weight: 700;
  height: 40px;
  color: #323232;
}

.c-header__navItem--hover {
  padding: 0 23px;
}

.c-header__navItem > a {
  display: block;
  position: relative;
  padding: 0 23px;
  font-size: 18px;
  font-weight: 700;
  height: 40px;
  color: #323232;
}

.c-header__navItem > a:after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: #fa3e3e;
  opacity: 0;
  transform: translateY(100%);
  transition: 200ms ease-out;
}

.c-header__navItem > a:hover:after {
  transform: translateY(0);
  opacity: 1;
}

.c-header__navItem:last-child a {
  padding-right: 15px;
}

.c-header__pulldown {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%) translateY(-10%);
  z-index: 5;
  background-color: #fa3e3e;
  min-width: 180px;
  opacity: 0;
  transition: 250ms ease-out;
  border-radius: 6px;
  pointer-events: none;
}

.c-header__pulldown:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
  width: 10px;
  height: 10px;
  transform: translate(0, -50%) rotate(45deg);
  background-color: #fa3e3e;
  border-radius: 2px;
  z-index: -1;
}

.c-header__navItem:hover .c-header__pulldown {
  pointer-events: auto;
  opacity: 1;
  transform: translateX(-50%) translateY(0);
}

.c-header__pulldownItem {
  display: flex;
  padding: 10px 15px;
  transition: 100ms ease-out;
  color: #fff;
  font-size: 16px;
  font-weight: normal;
  border-bottom: 1px solid #ff6e6e;
}

.c-header__pulldownItem:before {
  content: "";
  display: inline-block;
  margin-right: 10px;
  width: 6px;
  height: 24px;
  background-image: url("../img/button_icon04.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  vertical-align: bottom;
}

.c-header__pulldownItem:last-child {
  border-bottom: none;
}

.c-header__pulldownItem:hover {
  background-color: rgba(200, 200, 200, 0.2);
}

.c-header__menuButton {
  display: none;
}

@media screen and (max-width: 768px) {
  .c-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    border-top-width: 5px;
    z-index: 30;
    min-width: auto;
  }
  .c-header__inner {
    padding: 0 10px;
    height: 50px;
  }
  .c-header__left {
    display: flex;
    align-items: center;
  }
  .c-header__logo {
    width: 83px;
    min-width: 83px;
    margin: 1px 13px 0 3px;
  }
  .c-header__logo img {
    width: 100%;
  }
  .c-header__company {
    margin: 0;
    font-size: 14px;
  }
  .c-header__right {
    display: flex;
    flex-direction: column;
  }
  .c-header__menu {
    opacity: 0;
    pointer-events: none;
    position: fixed;
    top: 50px;
    left: 0;
    width: 100%;
    height: calc(100% - 50px);
    background-color: rgba(0, 0, 0, 0.2);
    overflow: auto;
    z-index: 100;
    transition: 250ms ease-out;
  }
  .c-header__menu.is-active {
    pointer-events: auto;
    opacity: 1;
  }
  .c-header__menu.is-active .c-header__linksWrap {
    transform: translate(0);
  }
  .c-header__linksWrap {
    flex: 1;
    order: 2;
    display: flex;
    flex-direction: column-reverse;
    margin: 0 0 0 auto;
    padding: 30px 20px;
    width: 300px;
    min-height: 350px;
    background-color: #fff;
    transform: translate(100%);
    transition: 250ms ease-out;
  }
  .c-header__links {
    justify-content: center;
    margin-bottom: 25px;
    padding: 0;
    width: 100%;
    border-right: none;
  }
  .c-header__link {
    margin-right: 22px;
  }
  .c-header__snsLink {
    width: 35px;
  }
  .c-header__snsLink img {
    width: 100%;
  }
  .c-header__tel {
    width: 100%;
  }
  .c-header__tel a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 60px;
    background-color: #fa3e3e;
    border-radius: 6px;
    color: #fff;
    font-size: 24px;
  }
  .c-header__tel a .c-icon-tel {
    font-weight: normal;
  }
  .c-header__tel p {
    margin-top: 5px;
    font-size: 12px;
    color: #646464;
    text-align: center;
  }
  .c-header__menu.is-active .c-header__nav {
    transform: translate(0);
  }
  .c-header__nav {
    order: 1;
    margin: 0 0 0 auto;
    width: 300px;
    background-color: #fff;
    transform: translate(100%);
    transition: 250ms ease-out;
  }
  .c-header__navList {
    display: block;
  }
  .c-header__navItem {
    border-bottom: 1px solid #e6e6e6;
    height: auto;
  }
  .c-header__navItem--hover {
    padding: 0;
  }
  .c-header__navItem--hover > span,
  .c-header__navItem > a {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 45px;
    background-color: #fff;
    font-size: 15px;
  }
  .c-header__navItem--hover > span {
    position: relative;
    padding: 0 23px;
  }
  .c-header__navItem--hover > span:before, .c-header__navItem--hover > span:after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    right: 23px;
    width: 15px;
    height: 1px;
    background-color: #fa3e3e;
    transition: 300ms ease-out;
  }
  .c-header__navItem--hover > span:after {
    transform: rotate(90deg);
  }
  .c-header__navItem--hover.is-active > span:after {
    transform: rotate(0);
  }
  .c-header__navItem > a:after {
    content: "";
    position: static;
    bottom: auto;
    left: auto;
    display: inline-block;
    transform: none;
    width: 15px;
    height: 15px;
    background-image: url("../img/button_icon01.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    background-color: transparent;
    opacity: 1;
  }
  .c-header__navItem:last-child > a {
    padding-right: 23px;
  }
  .c-header__pulldown {
    display: none;
    background-color: #f5f5f5;
    border-radius: 0;
    transition: none;
    pointer-events: auto;
  }
  .c-header__pulldown:before {
    content: none;
  }
  .c-header__pulldown,
  .c-header__navItem:hover .c-header__pulldown {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    opacity: 1;
    pointer-events: auto;
    transform: none;
  }
  .c-header__pulldownItem {
    margin: 0 20px;
    padding-left: 0;
    padding-right: 0;
    color: #505050;
    border-bottom: 1px solid #e6e6e6;
  }
  .c-header__pulldownItem:before {
    background-image: url("../img/button_icon03.svg");
  }
  .c-header__menuButton {
    display: block;
    position: relative;
    width: 26px;
    height: 20px;
  }
  .c-header__menuButton div {
    display: block;
    position: absolute;
    width: 26px;
    height: 3px;
    border-radius: 1.5px;
    background-color: #fa3e3e;
    transition: 250ms ease-out;
  }
  .c-header__menuButton div:nth-child(1) {
    top: 0;
    left: 0;
  }
  .c-header__menuButton div:nth-child(2) {
    top: calc(50% - 1px);
    left: 0;
    transform-origin: left center;
  }
  .c-header__menuButton div:nth-child(3) {
    top: calc(100% - 1px);
    left: 0;
  }
  .c-header__menuButton.is-active div:nth-child(1) {
    top: calc(50% - 1px);
    transform: rotate(45deg);
  }
  .c-header__menuButton.is-active div:nth-child(2) {
    transform: rotateY(90deg);
    opacity: 0;
  }
  .c-header__menuButton.is-active div:nth-child(3) {
    top: calc(50% - 1px);
    transform: rotate(-45deg);
  }
}

.c-footer {
  border-top: 1px solid #fa3e3e;
}

.c-footer__content {
  position: relative;
  padding-top: 60px;
  padding-bottom: 60px;
}

.c-footer__logoWrap {
  display: flex;
  align-items: flex-end;
  margin-bottom: 50px;
}

.c-footer__logo {
  margin-right: 20px;
}

.c-footer__company {
  font-size: 20px;
  font-weight: bold;
}

.c-footer__inner {
  display: flex;
}

.c-footer__info {
  margin-right: 80px;
  width: 266px;
  font-size: 14px;
}

.c-footer__address {
  margin-bottom: 15px;
}

.c-footer__tel {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  color: #fa3e3e;
  font-family: "Roboto", sans-serif;
  font-size: 30px;
  line-height: 1;
}

.c-footer__tel i {
  margin-right: 10px;
  color: #fa3e3e;
  font-size: 26px;
}

.c-footer__sns {
  display: flex;
  margin-top: 30px;
}

.c-footer__snsLink {
  display: block;
  margin-right: 18px;
  width: 36px;
  min-width: 36px;
  transition: 100ms ease-out;
}

.c-footer__snsLink:hover {
  opacity: 0.7;
}

.c-footer__snsLink img {
  width: 100%;
}

.c-footer__links {
  width: 300px;
  margin-right: 38px;
}

.c-footer__links:last-child {
  margin-right: 0;
}

.c-footer__largeLink {
  display: block;
  position: relative;
  font-size: 16px;
  line-height: 1;
  padding: 14px 3px;
  border-bottom: 1px solid #dcdcdc;
}

.c-footer__largeLink:after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  right: 3px;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
  background-image: url("../img/button_icon01.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.c-footer__largeLink:hover {
  background-color: rgba(200, 200, 200, 0.2);
}

.c-footer__largeLink--disable:hover {
  background-color: transparent;
}

.c-footer__largeLink--disable:after {
  content: none;
}

.c-footer__smallLinks {
  margin-top: 5px;
  margin-bottom: 8px;
}

.c-footer__smallLink {
  display: block;
  position: relative;
  padding: 10px 17px;
  font-size: 14px;
  line-height: 1;
}

.c-footer__smallLink:before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 4px;
  transform: translateY(-50%);
  width: 5px;
  height: 8px;
  background-image: url("../img/button_icon03.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.c-footer__smallLink:hover {
  background-color: rgba(200, 200, 200, 0.2);
}

.c-footer__copyright {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  color: #fff;
  font-size: 10px;
  font-family: "Roboto", sans-serif;
  background-color: #fa3e3e;
}

.c-footer__toTop {
  display: none;
  position: fixed;
  right: 100px;
  z-index: 19;
  bottom: 120px;
}

.c-footer__toTop a {
  position: absolute;
  width: 60px;
  height: 60px;
  display: block;
}

@media screen and (max-width: 768px) {
  .c-footer__content {
    position: relative;
    padding-top: 50px;
    padding-bottom: 30px;
  }
  .c-footer__logoWrap {
    display: block;
    margin-bottom: 25px;
  }
  .c-footer__logo {
    width: 197px;
    margin: 0 auto 10px;
  }
  .c-footer__logo img {
    width: 100%;
  }
  .c-footer__company {
    font-size: 18px;
    text-align: center;
  }
  .c-footer__address {
    font-size: 14px;
    text-align: center;
  }
  .c-footer__inner {
    display: block;
  }
  .c-footer__info {
    padding-bottom: 30px;
    width: 100%;
  }
  .c-footer__tel {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto 10px;
    max-width: 295px;
    height: 60px;
    border-radius: 6px;
    color: #fff !important;
    font-size: 26px;
    background-color: #fa3e3e;
  }
  .c-footer__tel span {
    font-size: 26px;
    color: #fff !important;
  }
  .c-footer__tel i {
    color: #fff;
    font-size: 21px;
  }
  .c-footer__time {
    font-size: 12px;
    text-align: center;
  }
  .c-footer__links {
    margin-left: -20px;
    width: calc(100% + 40px);
  }
  .c-footer__largeLink {
    padding: 15px 20px;
    height: 50px;
    font-size: 15px;
  }
  .c-footer__largeLink:after {
    right: 20px;
  }
  .c-footer__largeLink:first-child {
    border-top: 1px solid #dcdcdc;
  }
  .c-footer__largeLink--disable:last-of-type {
    border-bottom: none;
  }
  .c-footer__largeLink--disable:before, .c-footer__largeLink--disable:after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    right: 23px;
    width: 15px;
    height: 1px;
    background-color: #fa3e3e;
    transition: 300ms ease-out;
  }
  .c-footer__largeLink--disable.is-active {
    border-bottom: 1px solid #dcdcdc;
  }
  .c-footer__largeLink--disable:after {
    transform: rotate(90deg);
  }
  .c-footer__largeLink--disable.is-active:after {
    transform: rotate(0deg);
  }
  .c-footer__links:last-child .c-footer__largeLink--disable {
    border-bottom: 1px solid #dcdcdc;
  }
  .c-footer__smallLinks {
    display: none;
    margin: 0;
    padding: 0 20px;
    background-color: #f5f5f5;
    border-bottom: 1px solid #dcdcdc;
  }
  .c-footer__smallLinks:last-child {
    border-bottom: none;
  }
  .c-footer__smallLink {
    display: flex;
    align-items: center;
    background-color: transparent;
    height: 50px;
    border-bottom: 1px solid #dcdcdc;
  }
  .c-footer__smallLink:last-child {
    border-bottom: none;
  }
  .c-footer__sns {
    justify-content: space-around;
    margin-top: 28px;
  }
  .c-footer__snsLink {
    margin: 0;
    width: 40px;
  }
  .c-footer__snsLink img {
    width: 100%;
  }
  .c-footer__copyright {
    height: 50px;
    text-align: center;
    font-size: 10px;
    color: #fff !important;
  }
  .c-footer__toTop {
    position: fixed;
    right: 5px;
    top: auto;
    bottom: 150px;
    width: 50px;
  }
  .c-footer__toTop a {
    width: 50px;
    height: 50px;
  }
  .c-footer__toTop img {
    width: 100%;
  }
  .c-footer__toTop.js-fixstop {
    position: absolute;
    top: -25px;
    bottom: auto;
  }
}

.c-tabContent__tabs {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.c-tabContent__tab {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-right: 3px;
  height: 50px;
  color: #fff;
  font-size: 18px;
  border-radius: 4px;
  z-index: 3;
}

.c-tabContent__tab:last-child {
  margin-right: 0;
}

.c-tabContent__tab--primary {
  background-color: #fa3e3e;
}

.c-tabContent__tab--secondary {
  background-color: #ffb430;
}

.c-tabContent__tab--tertiary {
  background-color: #55ceec;
}

.c-tabContent__tab:before {
  content: '';
  display: block;
  position: absolute;
  bottom: 0px;
  left: 50%;
  width: 18px;
  height: 18px;
  border-radius: 2px;
  transform: rotate(45deg) translate(-50%);
  opacity: 0;
  z-index: -1;
}

.c-tabContent__tab.is-active:before {
  opacity: 1;
  bottom: -9px;
  transition: 250ms ease-out;
}

.c-tabContent__tab--primary:before {
  background-color: #fa3e3e;
}

.c-tabContent__tab--secondary:before {
  background-color: #ffb430;
}

.c-tabContent__tab--tertiary:before {
  background-color: #55ceec;
}

.c-tabContent__contents {
  position: relative;
}

.c-tabContent__content {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  padding: 40px;
  width: 100%;
  transition: 250ms ease-out;
  background-color: #fff;
  z-index: 2;
}

.c-tabContent__content.is-active {
  position: relative;
  pointer-events: auto;
  opacity: 1;
  z-index: 1;
}

@media screen and (max-width: 768px) {
  .c-tabContent__tab {
    height: 40px;
    font-size: 15px;
  }
  .c-tabContent__tab:before {
    width: 10px;
    height: 10px;
  }
  .c-tabContent__tab.is-active:before {
    bottom: -7px;
  }
  .c-tabContent__content {
    padding: 15px 20px;
  }
}

.c-newsList__item {
  border-top: 2px dashed #c8c8c8;
}

.c-newsList__item:last-child {
  border-bottom: 2px dashed #c8c8c8;
}

.c-newsList__item a {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 17px 5px;
  transition: 100ms ease-out;
}

.c-newsList__item a:hover {
  background-color: rgba(200, 200, 200, 0.2);
}

.c-newsList__meta {
  display: flex;
  justify-content: center;
  align-items: center;
}

.c-newsList__date {
  margin-right: 25px;
  min-width: 130px;
  color: #646464;
  font-size: 16px;
}

.c-newsList__category {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 24px;
  min-width: 100px;
  height: 24px;
  color: #fff;
  font-size: 12px;
  border-radius: 12px;
}

.c-newsList__category--primary {
  background-color: #fa3e3e;
}

.c-newsList__category--secondary {
  background-color: #ffb430;
}

.c-newsList__category--tertiary {
  background-color: #55ceec;
}

.c-newsList__text {
  color: #323232;
  font-size: 16px;
  word-wrap: break-word;
  word-break: break-all;
}

@media screen and (max-width: 768px) {
  .c-newsList__item {
    border-top: 1px dashed #c8c8c8;
  }
  .c-newsList__item:last-child {
    border-bottom: 1px dashed #c8c8c8;
  }
  .c-newsList__item a {
    padding: 10px 0;
    flex-wrap: wrap;
  }
  .c-newsList__meta {
    margin-bottom: 4px;
  }
  .c-newsList__date {
    margin-right: 10px;
    font-size: 14px;
  }
  .c-newsList__category {
    min-width: 100px;
    height: 20px;
    border-radius: 10px;
    font-size: 12px;
  }
  .c-newsList__text {
    font-size: 14px;
    line-height: 1.5;
  }
}

.c-bannerArea {
  width: 100%;
  overflow: hidden;
  padding: 67px 0 85px;
}

.c-bannerArea__slider {
  position: relative;
}

.c-bannerArea__banner {
  transition: 100ms ease-out;
}

.c-bannerArea__banner:hover {
  opacity: 0.7;
}

.c-bannerArea__banner img {
  width: 100%;
}

@media screen and (max-width: 768px) {
  .c-bannerArea {
    padding: 40px 0 65px;
  }
  .c-bannerArea .swiper-container {
    width: calc(100% - 60px);
  }
}

.c-fixedBanner {
  position: fixed;
  right: 15px;
  bottom: 140px;
  z-index: 20;
  width: 255px;
}

.c-fixedBanner a img {
  width: 100%;
  max-width: 100%;
  transition: 250ms ease-out;
}

.c-fixedBanner a:hover img {
  transform: scale(1.1);
}

@media screen and (max-width: 768px) {
  .c-fixedBanner {
    bottom: 20px;
    width: auto;
  }
  .c-fixedBanner a img {
    width: 128px;
    max-width: none;
  }
}

@media screen and (max-width: 768px) {
  .js-accordion {
    position: relative;
  }
  .js-accordion::before {
    content: "";
    width: 2px;
    height: 16px;
    background: #fa3e3e;
    position: absolute;
    right: 22px;
    top: 0;
    bottom: 0;
    margin: auto;
    -webkit-transition: .6s ease;
    -o-transition: .6s ease;
    transition: .6s ease;
  }
  .js-accordion::after {
    content: "";
    width: 16px;
    height: 2px;
    background: #fa3e3e;
    position: absolute;
    right: 15px;
    top: 0;
    bottom: 0;
    margin: auto;
    -webkit-transition: .6s ease;
    -o-transition: .6s ease;
    transition: .6s ease;
  }
  .js-accordion.is-accordion--open::before {
    transform: rotate(90deg);
  }
  .js-accordion.is-accordion--open::after {
    transform: rotate(-180deg);
  }
}

.c-commonGrid__first {
  margin: 0 0 50px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  align-items: flex-start;
}

.c-commonGrid__firstLeft {
  flex: 1;
}

.c-commonGrid__firstRight {
  width: 470px;
  min-width: 470px;
  margin: 0 0 0 50px;
}

.c-commonGrid__first .c-basicButton {
  margin: 0;
  width: 320px;
  height: 60px;
}

.c-commonGrid__first + .c-smallTile {
  margin: 50px 0 20px;
}

.c-commonGrid__text {
  margin: 0 0 35px;
}

.c-commonGrid__text .c-smallTile {
  text-align: left;
}

.c-commonGrid__text p {
  color: #323232;
  font-size: 16px;
  line-height: 1.7;
}

.c-commonGrid__first:last-of-type {
  margin: 0;
}

.c-commonGrid__second {
  margin: 0 0 50px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  align-items: flex-start;
}

.c-commonGrid__secondLeft {
  width: 470px;
  min-width: 470px;
  margin: 0 50px 0 0;
}

.c-commonGrid__secondRight {
  flex: 1;
}

.c-commonGrid__second .c-basicButton {
  margin: 0;
  width: 320px;
  height: 60px;
}

.c-commonGrid__second + .c-smallTile {
  margin: 50px 0 20px;
}

.c-commonGrid__second:last-of-type {
  margin: 0;
}

.c-commonGrid__image {
  margin: 0 auto 20px;
}

.c-commonGrid__text {
  margin: 0 0 35px;
}

.c-commonGrid__text .c-smallTile {
  text-align: left;
}

.c-commonGrid__text p {
  color: #323232;
  font-size: 16px;
  line-height: 1.7;
}

@media screen and (max-width: 768px) {
  .c-commonGrid__first {
    flex-direction: column;
  }
  .c-commonGrid__firstLeft {
    order: 2;
    flex: 1;
  }
  .c-commonGrid__firstRight {
    order: 1;
    width: 100%;
    min-width: auto;
    margin: 0 0 0;
  }
  .c-commonGrid__first .c-basicButton {
    width: 87%;
    height: 50px;
  }
  .c-commonGrid__first + .c-smallTile {
    margin: 20px 0 0;
  }
  .c-commonGrid__first:last-of-type .c-commonGrid__text {
    margin: 0;
  }
  .c-commonGrid__first:last-of-type .c-basicButton {
    margin: 20px 0 20px;
  }
  .c-commonGrid__second {
    flex-direction: column;
  }
  .c-commonGrid__secondLeft {
    order: 1;
    width: 100%;
    min-width: auto;
    margin: 0 0 0;
  }
  .c-commonGrid__secondRight {
    order: 2;
    flex: 1;
  }
  .c-commonGrid__second .c-basicButton {
    width: 87%;
    height: 50px;
  }
  .c-commonGrid__second + .c-smallTile {
    margin: 20px 0 20px;
  }
  .c-commonGrid__text p {
    font-size: 15px;
  }
  .c-commonGrid__image img {
    width: 100%;
    max-width: 100%;
  }
  .c-commonGrid__second:last-of-type .c-commonGrid__text {
    margin: 0;
  }
  .c-commonGrid__second:last-of-type .c-basicButton {
    margin: 20px 0 0;
  }
}

.c-table {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  margin: 0 0 120px;
}

.c-table__item {
  width: 100%;
}

.c-table__item:nth-of-type(1) {
  margin: 0 40px 0 0;
}

.c-table__item dl {
  line-height: 1.7;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  align-items: center;
  padding: 15px 25px 15px 25px;
}

.c-table__item dl:nth-of-type(odd) {
  background-color: #fbf8f4;
}

.c-table__item dl:last-of-type {
  margin: 0;
}

.c-table__item dt {
  font-weight: bold;
  margin: 0 0 0 0;
  width: 150px;
  min-width: 150px;
}

@media screen and (max-width: 768px) {
  .c-table {
    flex-direction: column;
    margin: 0 0 45px;
  }
  .c-table__item {
    width: 100%;
  }
  .c-table__item:nth-of-type(1) {
    margin: 0;
  }
  .c-table__item:nth-of-type(2) dl:nth-of-type(odd) {
    background-color: #fff;
  }
  .c-table__item:nth-of-type(2) dl:nth-of-type(2n) {
    background-color: #fbf8f4;
  }
  .c-table__item dl {
    padding: 15px;
    align-items: flex-start;
    flex-direction: column;
  }
}

.c-pagiNation__Block {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 50px 0 0;
}

.c-pagiNation__Block ul {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
}

.c-pagiNation__Block li {
  margin: 0 10px 10px 0;
}

.c-pagiNation__Block li:last-of-type {
  margin: 0 0 10px;
}

.c-pagiNation__Block li a,
.c-pagiNation__Block li span {
  border: 1px solid #fa3e3e;
  border-radius: 6px;
  width: 40px;
  height: 40px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fa3e3e;
  transition: 0.4s ease;
}

.c-pagiNation__Block li a:hover,
.c-pagiNation__Block li span:hover {
  background-color: #fa3e3e;
  color: #fff;
}

.c-pagiNation__Block li a.current,
.c-pagiNation__Block li span.current {
  background-color: #fa3e3e;
  color: #fff;
}

.c-pagiNation__Block a.prev,
.c-pagiNation__Block a.next {
  text-indent: -9999px;
  position: relative;
  border: 1px solid #fa3e3e;
  border-radius: 6px;
  border-radius: 6px;
  width: 40px;
  height: 40px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fa3e3e;
  transition: 0.4s ease;
}

.c-pagiNation__Block a.prev:hover,
.c-pagiNation__Block a.next:hover {
  background-color: #fa3e3e;
}

.c-pagiNation__Block a.prev:hover::after,
.c-pagiNation__Block a.next:hover::after {
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
}

.c-pagiNation__Block a.prev::after {
  transition: 0.4s ease;
  position: absolute;
  top: 15px;
  left: 16px;
  width: 9px;
  height: 9px;
  transform: rotate(225deg);
  border-top: 2px solid #fa3e3e;
  border-right: 2px solid #fa3e3e;
  content: '';
}

.c-pagiNation__Block a.next::after {
  transition: 0.4s ease;
  position: absolute;
  top: 15px;
  left: 13px;
  width: 9px;
  height: 9px;
  transform: rotate(45deg);
  border-top: 2px solid #fa3e3e;
  border-right: 2px solid #fa3e3e;
  content: '';
}

@media screen and (max-width: 768px) {
  .c-pagiNation__Block {
    margin: 30px 0 0;
  }
  .c-pagiNation__Block .prev {
    margin: 0 8px 8px 0;
  }
  .c-pagiNation__Block .next {
    margin: 0 0 8px 8px;
  }
  .c-pagiNation__Block li {
    margin: 0 8px 8px 0;
  }
  .c-pagiNation__Block li:last-of-type {
    margin: 0 0 8px;
  }
}

.c-breadcrumbList {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  margin: 0 0 60px;
}

.c-breadcrumbList__item {
  font-size: 12px;
  color: #323232;
  padding: 0 15px 0 0;
}

.c-breadcrumbList__item a {
  position: relative;
  color: #787878;
  transition: 0.4s ease;
}

.c-breadcrumbList__item a::after {
  content: '>';
  position: absolute;
  top: -4px;
  right: -11px;
  color: #787878;
}

.c-breadcrumbList__item a:hover {
  opacity: 0.7;
}

@media screen and (max-width: 768px) {
  .c-breadcrumbList {
    display: none;
  }
}

.p-mv {
  position: relative;
}

.p-mv__slider {
  position: relative;
}

.p-mv__slide {
  width: 75%;
}

.p-mv__slide a {
  display: block;
  width: 100%;
  overflow: hidden;
}

.p-mv__slide a img {
  width: 100%;
  transition: 500ms ease-out;
}

.p-mv__slide a:hover img {
  transform: scale(1.05);
  opacity: 0.8;
}

.p-mv__prev {
  left: 9%;
}

.p-mv__next {
  right: 9%;
}

.p-topNews {
  background-image: url("../img/top_news_bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.p-topNews__button {
  text-align: center;
  margin: 30px auto 0;
}

.p-topAbout {
  background-color: #fbf8f4;
}

.p-topAbout__image {
  display: block;
  position: relative;
  width: 980px;
}

.p-topAbout__image img {
  width: 100%;
}

.p-topAbout__charactor01 {
  position: absolute;
  top: -123px;
  left: 79px;
}

.p-topAbout__charactor02 {
  position: absolute;
  right: 70px;
  bottom: -15px;
}

.p-topAbout__charactor03 {
  display: none;
}

.p-topAbout__charactor04 {
  display: none;
}

.p-topAbout__content {
  position: relative;
  margin: -70px auto 0;
  padding: 45px 50px 30px;
  width: 800px;
  background-color: #fff;
  border-radius: 10px;
}

.p-topAbout__text {
  text-align: center;
  line-height: 1.75;
}

.p-topAbout__buttons {
  display: flex;
  flex-wrap: wrap;
  margin: 30px 0 0;
}

.p-topAbout__button {
  margin: 0;
  margin-right: 20px;
  margin-bottom: 20px;
  width: 220px;
  height: 60px;
}

.p-topAbout__button:nth-child(3n) {
  margin-right: 0;
}

.p-topProduct {
  background-image: url("../img/top_product_bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.p-topProduct__text {
  text-align: center;
  line-height: 1.75;
}

.p-topProduct__image {
  margin: 0 0 0;
}

.p-topProduct__image img {
  width: 100%;
}

.p-topProduct__buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 50px 0 0;
}

.p-topProduct__button {
  margin: 0;
  margin-right: 20px;
  width: 280px;
  height: 60px;
}

.p-topProduct__button:last-child {
  margin-right: 0;
}

.p-topRecruit {
  background-color: #fbf8f4;
}

.p-topRecruit__grid {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.p-topRecruit__gridLeft {
  width: 360px;
  min-width: 360px;
}

.p-topRecruit__gridRight {
  flex: 1;
  margin-left: 50px;
}

.p-topRecruit__text {
  width: 350px;
  line-height: 1.75;
}

.p-topRecruit__buttons {
  margin-top: 35px;
}

.p-topRecruit__button {
  margin: 0;
  margin-bottom: 20px;
  width: 280px;
  height: 60px;
}

.p-topRecruit__button:last-child {
  margin-bottom: 0;
}

.p-topContact {
  background-image: url("../img/top_contact_bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.p-topContact .c-largeTitle {
  position: relative;
}

.p-topContact .c-largeTitle__icon, .p-topContact .c-largeTitle__main, .p-topContact .c-largeTitle__sub {
  color: #fff;
}

.p-topContact .c-largeTitle:before, .p-topContact .c-largeTitle:after {
  content: "";
  display: block;
  position: absolute;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
}

.p-topContact .c-largeTitle:before {
  top: 25px;
  left: 245px;
  width: 75px;
  height: 102px;
  background-image: url("../img/top_contact_title_img01.png");
}

.p-topContact .c-largeTitle:after {
  top: 15px;
  left: 660px;
  width: 68px;
  height: 102px;
  background-image: url("../img/top_contact_title_img02.png");
}

.p-topContact__text {
  color: #fff;
  text-align: center;
}

.p-topContact__buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

.p-topContact__button {
  display: block;
  margin-right: 25px;
  width: 360px;
  height: 90px;
}

.p-topContact__button:last-child {
  margin-right: 0;
}

.p-topContact__button--tel {
  position: relative;
  padding: 20px 30px;
  color: #fff;
}

.p-topContact__button--tel:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 12px);
  height: 100%;
  border-top: 2px solid #fff;
  border-bottom: 2px solid #fff;
}

.p-topContact__button--tel:after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 100%;
  height: calc(100% - 12px);
  border-left: 2px solid #fff;
  border-right: 2px solid #fff;
}

.p-topContact__button--tel .p-topContact__button__large {
  display: flex;
  align-items: center;
  font-size: 36px;
  font-weight: bold;
  vertical-align: middle;
  line-height: 1;
  font-family: "Roboto", sans-serif;
}

.p-topContact__button--tel .p-topContact__button__large i {
  margin-right: 10px;
  font-size: 26px;
}

.p-topContact__button--tel .p-topContact__button__small {
  display: block;
  text-align: center;
  font-size: 12px;
}

.p-topContact__button--form {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fa3e3e;
  font-weight: bold;
  font-size: 24px;
  border-radius: 6px;
  background-color: #fff;
}

.p-topContact__button--form i {
  margin-right: 10px;
  font-size: 26px;
  color: #fa3e3e;
}

@media screen and (max-width: 768px) {
  .p-mv__prev {
    left: 0;
  }
  .p-mv__next {
    right: 0;
  }
  .p-topNews {
    background-image: url("../img/top_news_bg_sp.jpg");
  }
  .p-topNews__button {
    margin-top: 20px;
  }
  .p-topAbout {
    width: 100%;
    padding: 60px 0 95px;
  }
  .p-topAbout__image {
    width: 100%;
  }
  .p-topAbout .c-largeTitle {
    position: relative;
  }
  .p-topAbout__charactor01 {
    display: none;
  }
  .p-topAbout__charactor02 {
    width: 115px;
    left: 50%;
    bottom: -40px;
    transform: translate(-50%);
  }
  .p-topAbout__charactor02 img {
    width: 100%;
  }
  .p-topAbout__charactor03, .p-topAbout__charactor04 {
    display: block;
    position: absolute;
  }
  .p-topAbout__charactor03 img, .p-topAbout__charactor04 img {
    width: 100%;
  }
  .p-topAbout__buttons {
    margin: 15px 0 0;
  }
  .p-topAbout__charactor03 {
    width: 65px;
    bottom: 0;
    left: 0;
  }
  .p-topAbout__charactor04 {
    width: 48px;
    bottom: 0;
    right: 6px;
  }
  .p-topAbout__content {
    margin-top: -25px;
    padding: 15px 20px 50px;
    width: calc(100% - 20px);
  }
  .p-topAbout__text {
    text-align: left;
  }
  .p-topAbout__button {
    width: 100%;
    height: 50px;
    margin: 0 0 10px;
  }
  .p-topProduct__image {
    max-width: 289px;
    margin-left: auto;
    margin: auto;
  }
  .p-topProduct__image img {
    width: 100%;
  }
  .p-topProduct__buttons {
    display: block;
    margin-top: 30px;
  }
  .p-topProduct__button {
    margin: 0 auto 10px;
    max-width: 295px;
  }
  .p-topProduct__button:last-child {
    margin: 0 auto;
  }
  .p-topRecruit__imageSp {
    margin: 0 0 25px;
    width: calc(100% + 20px);
  }
  .p-topRecruit__imageSp img {
    width: 100%;
  }
  .p-topRecruit__grid {
    display: block;
  }
  .p-topRecruit__gridLeft {
    width: 100%;
    min-width: 0;
  }
  .p-topRecruit__gridRight {
    display: none;
  }
  .p-topRecruit__text {
    width: 100%;
  }
  .p-topRecruit__buttons {
    display: block;
    margin-top: 25px;
  }
  .p-topRecruit__button {
    width: auto;
    max-width: 295px;
    height: 50px;
    margin: 0 0 10px;
  }
  .p-topRecruit__button:last-child {
    margin: 0;
  }
  .p-topContact .c-largeTitle:before {
    top: 15px;
    left: 0;
    width: 61px;
    height: 80px;
  }
  .p-topContact .c-largeTitle:after {
    top: 8px;
    right: 0;
    left: auto;
    width: 55px;
    height: 83px;
  }
  .p-topContact__text {
    text-align: left;
  }
  .p-topContact__buttons {
    display: block;
    margin-top: 25px;
  }
  .p-topContact__button {
    width: 100%;
    height: auto;
    min-height: 80px;
  }
  .p-topContact__button--tel, .p-topContact__button--form {
    background-color: #fff;
    color: #fa3e3e;
    text-align: center;
    border-radius: 6px;
    padding: 20px 15px;
  }
  .p-topContact__button--tel {
    margin-bottom: 15px;
  }
  .p-topContact__button--tel:before, .p-topContact__button--tel:after {
    content: none;
  }
  .p-topContact__button--tel .p-topContact__button__large {
    justify-content: center;
    font-size: 28px;
  }
  .p-topContact__button--tel .p-topContact__button__large i {
    font-size: 24px;
  }
  .p-topContact__button--tel .p-topContact__button__small {
    font-size: 12px;
  }
  .p-topContact__button--form {
    font-size: 21px;
  }
  .p-topContact__button--form i {
    font-size: 23px;
  }
}

.p-phi__read {
  position: relative;
  max-width: 730px;
  padding: 20px 0;
  margin: 0 auto 70px;
  line-height: 1.5;
  text-align: center;
  font-weight: bold;
  color: #fa3e3e;
  font-size: 28px;
}

.p-phi__read::before {
  content: '';
  position: absolute;
  bottom: -25px;
  right: -25px;
  box-sizing: border-box;
  width: 25px;
  height: 25px;
  border: 25px solid transparent;
  border-left: 25px solid #fa3e3e;
  transform: rotate(45deg);
}

.p-phi__read::after {
  content: '';
  position: absolute;
  top: -25px;
  left: -25px;
  box-sizing: border-box;
  width: 25px;
  height: 25px;
  border: 25px solid transparent;
  border-left: 25px solid #fa3e3e;
  transform: rotate(-135deg);
}

.p-phiHistory__grid {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  align-items: flex-start;
  margin: 0 0 80px;
}

.p-phiHistory__gridLeft {
  width: 302px;
  min-width: 302px;
}

.p-phiHistory__gridRight {
  flex: 1;
  margin: 0 0 0 40px;
}

.p-phiHistory__image {
  margin: 0 0 15px;
}

.p-phiHistory__image img {
  width: 100%;
  max-width: 100%;
}

.p-phiHistory__textName {
  line-height: 1.5;
  color: #323232;
  font-size: 16px;
  margin: 0 0 10px;
  font-weight: bold;
}

.p-phiHistory__textName span.p-phiHistory__textName--large {
  font-size: 24px;
  color: #fa3e3e;
  font-weight: bold;
}

.p-phiHistory__textName span.p-phiHistory__textName--small {
  font-size: 16px;
  color: #fa3e3e;
  font-weight: bold;
}

.p-phiHistory__textPost {
  color: #323232;
  font-size: 14px;
  line-height: 1.5;
}

.p-phiHistory__read {
  color: #323232;
}

.p-phiHistory__read p {
  line-height: 1.7;
  margin: 0 0 50px;
}

.p-phiHistory__read dl {
  line-height: 1.7;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  align-items: flex-start;
  padding: 15px 0 15px 25px;
}

.p-phiHistory__read dl:nth-of-type(odd) {
  background-color: #fbf8f4;
}

.p-phiHistory__read dl:last-of-type {
  margin: 0;
}

.p-phiHistory__read dt {
  font-weight: bold;
  margin: 0 40px 0 0;
  width: 170px;
  min-width: 170px;
}

.p-phiShirota__Block {
  padding: 60px 0;
  background-color: #fbf8f4;
}

.p-phiShirota__Block__text {
  color: #323232;
  line-height: 1.7;
  text-align: center;
  margin: 0 0 25px;
}

.p-phiShirota__Block__image {
  width: 560px;
  margin: auto;
}

.p-phiShirota__Block__image img {
  width: 100%;
  max-width: 100%;
}

.p-phiAbout {
  padding: 55px 0 75px;
}

.p-phiAbout__grid .c-basicButton {
  font-size: 18px;
}

@media screen and (max-width: 768px) {
  .p-phi__read {
    margin: 0 0 45px;
    padding: 0;
    width: 100%;
    max-width: none;
    font-size: 22px;
  }
  .p-phi__read::before {
    bottom: -20px;
    right: -20px;
    width: 20px;
    height: 20px;
    border: 20px solid transparent;
    border-left: 20px solid #fa3e3e;
  }
  .p-phi__read::after {
    top: -20px;
    left: -20px;
    width: 20px;
    height: 20px;
    border: 20px solid transparent;
    border-left: 20px solid #fa3e3e;
  }
  .p-phiHistory__grid {
    margin: 0 0 42px;
    flex-direction: column;
  }
  .p-phiHistory__gridLeft {
    width: 100%;
    min-width: auo;
  }
  .p-phiHistory__gridRight {
    margin: 0;
  }
  .p-phiHistory__text {
    margin: 0 0 30px;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
  }
  .p-phiHistory__textName {
    font-size: 15px;
    margin: 0 45px 0 0;
  }
  .p-phiHistory__textName span.p-phiHistory__textName--large {
    font-size: 20px;
  }
  .p-phiHistory__textName span.p-phiHistory__textName--small {
    font-size: 15px;
  }
  .p-phiHistory__read p {
    margin: 0 0 40px;
    font-size: 15px;
  }
  .p-phiHistory__read dl {
    flex-direction: column;
    padding: 15px;
  }
  .p-phiShirota__Block {
    padding: 30px 17px;
  }
  .p-phiShirota__Block__image {
    width: 90%;
  }
  .p-phiAbout__text {
    margin: 0 0 25px;
  }
  .p-phiAbout__text p {
    font-size: 15px;
  }
  .p-phiAbout__image img {
    width: 100%;
    max-width: 100%;
  }
  .p-phiAbout__button {
    width: 78.66%;
    height: 50px;
  }
  .p-phiAbout__grid .c-basicButton {
    font-size: 17px;
  }
}

.p-comp__image {
  margin: 0 auto 40px;
  text-align: center;
}

.p-comp__text {
  width: 800px;
  margin: 0 auto 95px;
}

.p-comp__text p {
  font-size: 16px;
  color: #323232;
  line-height: 1.7;
}

.p-compTable {
  margin: 0 0 125px;
}

.p-compTable__item {
  width: 50%;
}

.p-compTable__item:nth-of-type(1) {
  margin: 0 40px 0 0;
}

.p-compContact {
  text-align: center;
  width: 800px;
  margin: auto;
  border-radius: 20px;
  padding: 0 0 40px;
  border: 2px solid #fa3e3e;
}

.p-compContact__title {
  position: relative;
  top: -25px;
  width: 580px;
  margin: auto;
  font-size: 24px;
  color: #323232;
  font-weight: bold;
  background-color: #fff;
}

.p-compContact__title::before {
  content: '';
  position: absolute;
  left: 20px;
  top: 0;
  background-color: #fa3e3e;
  width: 2px;
  height: 60px;
  transform: rotate(-20deg);
}

.p-compContact__title::after {
  content: '';
  position: absolute;
  right: 20px;
  top: 0;
  background-color: #fa3e3e;
  width: 2px;
  height: 60px;
  transform: rotate(20deg);
}

.p-compContact__tel {
  position: relative;
  margin: -15px 0 0;
}

.p-compContact__tel a {
  font-family: "Roboto", sans-serif;
  font-size: 60px;
  font-weight: bold;
  color: #fa3e3e;
}

.p-compContact__tel a span {
  position: relative;
  right: -7px;
}

.p-compContact__tel a .c-icon-tel {
  font-size: 55px;
  margin: 0 0 0;
  position: relative;
  top: -5px;
  left: 8px;
  font-weight: bold;
}

.p-compContact__tel::before {
  content: '';
  position: absolute;
  top: 0;
  left: 70px;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(../../../company/img/comp_chara01.png);
  width: 61px;
  height: 100px;
}

.p-compContact__tel::after {
  content: '';
  position: absolute;
  top: 0;
  right: 70px;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(../../../company/img/comp_chara02.png);
  width: 61px;
  height: 100px;
}

.p-compContact__text {
  margin: -15px 0 0;
}

.p-compContact__text p {
  text-align: center;
  color: #505050;
  font-size: 14px;
}

@media screen and (max-width: 768px) {
  .p-comp__image {
    margin: 0 0 20px;
  }
  .p-comp__image img {
    width: 100%;
    max-width: 100%;
  }
  .p-comp__text {
    margin: 0 0 25px;
    width: 100%;
  }
  .p-comp__text p {
    font-size: 15px;
  }
  .p-compTable {
    margin: 0 0 35px;
  }
  .p-compTable__item {
    width: 100%;
  }
  .p-compTable__item:nth-of-type(1) {
    margin: 0;
  }
  .p-compTable__item:nth-of-type(2) dl:nth-of-type(odd) {
    background-color: #fff;
  }
  .p-compTable__item:nth-of-type(2) dl:nth-of-type(even) {
    background-color: #fbf8f4;
  }
  .p-compContact {
    width: 100%;
    padding: 0;
    border: none;
  }
  .p-compContact__title {
    position: relative;
    top: auto;
    width: auto;
    font-size: 17px;
    margin: 0 auto 10px;
  }
  .p-compContact__title::before {
    height: 70px;
    transform: rotate(-27deg);
  }
  .p-compContact__title::after {
    height: 70px;
    transform: rotate(27deg);
  }
  .p-compContact__tel {
    margin: 0 auto;
  }
  .p-compContact__tel img {
    width: 100%;
    max-width: 100%;
  }
  .p-compContact__tel::before {
    display: none;
  }
  .p-compContact__tel::after {
    display: none;
  }
  .p-compContact__tel a {
    width: 100%;
    height: auto;
    padding: 13px 0 30px;
    display: block;
    border-radius: 6px;
    background-color: #fa3e3e;
    font-size: 29px;
    color: #fff;
  }
  .p-compContact__tel a span {
    display: inline-block;
    margin: 0 0 8px;
    right: -4px;
  }
  .p-compContact__tel a .c-icon-tel {
    font-size: 29px;
    left: 4px;
    top: -5px;
  }
  .p-compContact__text {
    margin: -37px 0 0;
  }
  .p-compContact__text p {
    color: #fff;
    font-size: 12px;
  }
}

.p-sectionBusiness {
  padding: 70px 0 100px;
  background-color: #fbf8f4;
}

.p-busi__text {
  width: 800px;
  margin: 0 auto 45px;
}

.p-busi__text p {
  line-height: 1.7;
  color: #323232;
  font-size: 16px;
}

.p-busiList {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  align-items: flex-start;
}

.p-busiList .swiper-wrapper {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
}

.p-busiList .swiper-slide {
  width: auto;
}

.p-busiList .c-sliderNext01,
.p-busiList .c-sliderPrev01 {
  display: none;
}

.p-busiList__item {
  text-align: center;
  margin: 0 40px 0 0;
}

.p-busiList__item:last-of-type {
  margin: 0;
}

.p-busiList__item a {
  display: block;
  transition: 0.4s ease;
}

.p-busiShirota__button
.p-busiList__item a:hover {
  opacity: 0.7;
}

.p-busiList__image {
  margin: 0 auto 20px;
}

.p-busiList__text p {
  font-size: 20px;
  font-weight: bold;
  color: #fa3e3e;
}

.p-busiAbout__grid {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  align-items: flex-start;
}

.p-busiAbout__gridLeft {
  width: 470px;
  min-width: 470px;
  margin: 0 40px 0 0;
}

.p-busiAbout__text p {
  font-size: 16px;
  line-height: 1.7;
  color: #323232;
}

.p-sectionBusiShirota {
  padding: 50px 0 85px;
  background-color: #fbf8f4;
}

.p-sectionBusiShirota .c-middleTitle {
  margin: 0 auto 25px;
}

.p-busiShirota__read {
  margin: 0 auto 40px;
}

.p-busiShirota__read p {
  text-align: center;
  line-height: 1.7;
  font-size: 16px;
  color: #323232;
}

.p-busiShirota__button {
  width: 360px;
  margin: auto;
  height: 60px;
  font-size: 18px;
}

.p-sectionBusiAbout {
  padding: 0px 0 100px;
}

@media screen and (max-width: 768px) {
  .p-busi__text {
    width: auto;
    margin: 0 0 20px;
  }
  .p-busi__text p {
    font-size: 15px;
  }
  .p-busiList .swiper-container {
    padding: 0 0 40px;
  }
  .p-busiList .swiper-button-next,
  .p-busiList .swiper-button-prev {
    top: 40%;
  }
  .p-busiList .swiper-button-next {
    right: -10px;
  }
  .p-busiList .swiper-button-prev {
    left: -10px;
  }
  .p-busiList .c-sliderNext01,
  .p-busiList .c-sliderPrev01 {
    display: block;
  }
  .p-busiAbout__grid {
    flex-direction: column;
  }
  .p-busiAbout__gridLeft {
    width: 100%;
    min-width: auto;
  }
  .p-busiAbout__image {
    margin: 0 0 20px;
  }
  .p-busiAbout__image img {
    width: 100%;
    max-width: 100%;
  }
  .p-busiAbout__text p {
    font-size: 15px;
  }
  .p-busiShirota__read p {
    font-size: 15px;
  }
  .p-busiShirota__button {
    width: 100%;
    height: 50px;
    font-size: 17px;
  }
  .p-busiShirota__button {
    font-size: 17px;
    height: 50px;
  }
  .p-sectionBusiAbout {
    padding: 0px 0 60px;
  }
}

.p-cenList {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  flex-wrap: wrap;
}

.p-cenList__item {
  width: 32.1%;
  margin: 0 18px 18px 0;
}

.p-cenList__item:nth-of-type(3n) {
  margin: 0 0 18px;
}

.p-cenList__item a {
  position: relative;
  border: 2px solid #fa3e3e;
  width: 100%;
  height: 50px;
  font-size: 20px;
  color: #fa3e3e;
  text-align: center;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  transition: 0.4s ease;
}

.p-cenList__item a:hover {
  opacity: 0.7;
}

.p-cenList__item a::after {
  position: absolute;
  top: 15px;
  width: 16px;
  height: 16px;
  right: 20px;
  transform: rotate(90deg);
  background-image: url(../img/button_icon01.svg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  content: '';
}

.p-cenCat__list {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  position: absolute;
  top: 11px;
  left: 11px;
}

.p-cenCat__item {
  color: #fff;
  margin: 0 4px 0 0;
  padding: 2px 13px;
  font-size: 12px;
  border-radius: 10px;
}

.p-cenCat__item:last-of-type {
  margin: 0;
}

.p-cenCat__item--childcare {
  background-color: #ffab3b;
}

.p-cenCat__item--salon {
  background-color: #ff7575;
}

.p-sectionCenAria {
  background-color: #fbf8f4;
}

.p-cenAria__Block {
  margin: 0 0 50px;
}

.p-cenAria__Block:last-of-type {
  margin: 0;
}

.p-cenAria__title {
  font-size: 28px;
  line-height: 1.5;
  color: #323232;
  margin: 0 0 40px;
  padding: 0 0 15px;
  border-bottom: 4px dotted #fa3e3e;
}

.p-cenAria__title a {
  display: block;
}

.p-cenAriaList {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  flex-wrap: wrap;
}

.p-cenAriaList__item {
  width: 31.75%;
  position: relative;
  background-color: #fff;
  margin: 0 23px 23px 0;
  border-radius: 10px 10px 0 0;
  overflow: hidden;
}

.p-cenAriaList__item:nth-of-type(3n) {
  margin: 0 0 23px;
}

.p-cenAriaList img {
  width: 100%;
  max-width: 100%;
}

.p-cenAriaList__text {
  padding: 20px;
}

.p-cenAriaList__text h4 {
  margin: 0 0 15px;
  line-height: 1.5;
  font-size: 20px;
  color: #fa3e3e;
}

.p-cenAriaList__text p {
  line-height: 1.5;
  font-size: 14px;
  color: #323232;
}

.p-cenAriaList__button {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  padding: 0 20px 20px;
}

.p-cenAriaList__button a {
  font-size: 14px;
  width: 50%;
  height: 34px;
}

.p-cenAriaList__button a:nth-of-type(1) {
  margin: 0 11px 0 0;
}

.p-cenAriaList__button--map::after {
  display: block;
  width: 12px;
  height: 16px;
  margin-right: 0;
  margin-left: auto;
  background-image: url(../img/button_icon04.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  content: "";
}

.p-cenAriaList__button--detail::after {
  display: block;
  width: 14px;
  height: 14px;
  margin-right: 0;
  margin-left: auto;
  background-image: url(../img/button_icon05.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  content: "";
}

.p-cenSalon__buttonWrap > p {
  line-height: 1.7;
  color: #323232;
  margin: 0 0 20px;
}

.p-cenSalon__buttonWrap .c-basicButton {
  width: 300px;
  margin: 0;
}

@media screen and (max-width: 768px) {
  .p-cenAria__Block {
    border-top: 2px dotted #fa3e3e;
    margin: 0;
  }
  .p-cenAria__Block:last-of-type .p-cenAria__title {
    border-bottom: 2px dotted #fa3e3e;
  }
  .p-cenAria__Block:last-of-type .p-cenAriaList {
    border-top: none;
  }
  .p-cenAria__title {
    font-size: 22px;
    margin: -50px 0 0;
    padding: 65px 0 15px;
    border-top: none;
    border-bottom: none;
  }
  .p-cenAria__title a {
    position: relative;
  }
  .p-cenList__item:nth-of-type(3n) {
    margin: 0 auto 9px;
  }
  .p-cenList__item {
    width: 48%;
    margin: 0 2% 9px 0;
  }
  .p-cenList__item:nth-of-type(even) {
    margin: 0 0 9px;
  }
  .p-cenList__item:nth-of-type(3n) {
    margin: 0 2% 9px 0;
  }
  .p-cenList__item:last-of-type {
    margin: 0 0 9px;
  }
  .p-cenList__item a {
    height: auto;
    padding: 10px 17px;
    font-size: 14px;
  }
  .p-cenList__item a::after {
    right: 8px;
    width: 13px;
  }
  .p-cenAriaList {
    display: none;
    flex-direction: column;
  }
  .p-cenAriaList {
    border-top: 2px dotted #fa3e3e;
    padding: 20px 0 0;
    margin: 0 0 35px;
  }
  .p-cenAriaList__item {
    width: 100%;
    border-radius: 20px 20px 0 0;
    margin: 0 0 20px;
  }
  .p-cenAriaList__item:last-of-type {
    margin: 0;
  }
  .p-cenAriaList__image img {
    width: 100%;
    max-width: 100%;
  }
  .p-cenAriaList__text h4 {
    margin: 0 0 10px;
    font-size: 18px;
  }
  .p-cenAriaList__button a {
    height: 40px;
  }
  .p-cenSalon__buttonWrap {
    display: none;
  }
  .p-cenSalon__buttonWrap > p {
    font-size: 15px;
    margin: 20px 0 20px;
  }
  .p-cenSalon__buttonWrap .p-cenSalon__button {
    margin: 20px 0 0;
    height: 50px;
  }
}

.p-pro__grid {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  align-items: flex-start;
  margin: 0 0 40px;
}

.p-pro__gridLeft {
  width: 465px;
  min-width: 465px;
}

.p-pro__gridRight {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  margin: 0 0 0 50px;
}

.p-pro__text {
  margin: 0 0 35px;
}

.p-pro__text h2.c-smallTile {
  text-align: left;
}

.p-pro__text p {
  font-size: 16px;
  color: #323232;
  line-height: 1.7;
}

.p-pro__button {
  width: 320px;
  height: 60px;
  margin: 40px auto 60px;
}

.p-proList__wrap {
  background-color: #fbf8f4;
  border-radius: 10px;
  padding: 30px 50px 20px;
}

.p-proList__title {
  text-align: center;
  font-size: 24px;
  width: 380px;
  margin: auto;
  position: relative;
}

.p-proList__title::before {
  position: absolute;
  top: 4px;
  left: 20px;
  width: 2px;
  height: 30px;
  transform: rotate(-20deg);
  background-color: #fa3e3e;
  content: '';
}

.p-proList__title::after {
  position: absolute;
  top: 4px;
  right: 20px;
  width: 2px;
  height: 30px;
  transform: rotate(20deg);
  background-color: #fa3e3e;
  content: '';
}

.p-proList {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  flex-wrap: wrap;
  margin: 30px 0 0;
}

.p-proList__item {
  width: 23%;
  margin: 0 23px 23px 0;
}

.p-proList__item:nth-of-type(4n) {
  margin: 0 0 23px;
}

.p-proList__item img {
  width: 100%;
  max-width: 100%;
}

.p-proList__item img:hover {
  opacity: 0.8;
}

.p-proList__button {
  height: 50px;
  width: 100%;
}

.p-proCosme__grid:last-of-type {
  margin: 50px 0 0;
}

.p-serNet__buttonWrap {
  text-align: center;
}

.p-serNet__buttonWrap .p-serNet__button {
  display: block;
  font-size: 18px;
}

.p-serNet__buttonWrap .p-serNet__button:after {
  position: absolute;
  top: 52%;
  right: 20px;
  transform: translateY(-50%);
}

@media screen and (max-width: 768px) {
  .p-pro__grid {
    flex-direction: column;
  }
  .p-pro__gridLeft {
    width: 100%;
    min-width: auto;
  }
  .p-pro__gridRight {
    margin: 0 0 20px;
  }
  .p-pro__image img {
    width: 100%;
    max-width: 100%;
  }
  .p-pro__text p {
    font-size: 15px;
  }
  .p-pro__button {
    width: 80%;
    margin: 40px auto 40px;
  }
  .p-proList {
    flex-wrap: wrap;
  }
  .p-proList__item {
    width: 48%;
    margin: 0 2% 7px 0;
  }
  .p-proList__item:nth-of-type(4n) {
    margin: 0 2% 7px 0;
  }
  .p-serNet__button {
    font-size: 17px;
  }
  .p-proList__wrap {
    padding: 20px;
  }
  .p-proList__title {
    width: 290px;
    font-size: 18px;
  }
  .p-proList__title::before {
    top: 1px;
    height: 25px;
  }
  .p-proList__title::after {
    top: 1px;
    height: 25px;
  }
}

.p-serList {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin: 0 0 60px;
}

.p-serList__item {
  margin: 0 110px 0 0;
}

.p-serList__item:last-of-type {
  margin: 0;
}

.p-serList__item:nth-of-type(1) {
  position: relative;
}

.p-serList__item:nth-of-type(1)::after {
  content: '';
  position: absolute;
  top: 70px;
  right: -65px;
  width: 21px;
  height: 25px;
  background-image: url(../../../service/img/ser_arrow.png);
  background-size: auto;
  background-repeat: no-repeat;
  background-position: center center;
}

.p-serList__item:nth-of-type(2) {
  position: relative;
}

.p-serList__item:nth-of-type(2)::after {
  content: '';
  position: absolute;
  top: 70px;
  right: -65px;
  width: 21px;
  height: 25px;
  background-image: url(../../../service/img/ser_arrow.png);
  background-size: auto;
  background-repeat: no-repeat;
  background-position: center center;
}

.p-serList__img {
  margin: 0 auto 10px;
}

.p-serList__text p {
  text-align: center;
  font-weight: bold;
  line-height: 1.7;
  color: #323232;
  font-size: 20px;
}

.p-serFlow__Block {
  margin: 0 0 20px;
  padding: 40px;
  background-color: #fbf8f4;
  overflow: hidden;
  position: relative;
}

.p-serFlow__Block::before {
  content: '';
  position: absolute;
  bottom: 0;
  width: 200px;
  height: 200px;
  background-size: 50%;
  background-position: left top;
  background-repeat: no-repeat;
}

.p-serFlow__Block:nth-of-type(1)::before {
  left: 310px;
  bottom: -65px;
  background-size: 45%;
  background-image: url(../../../service/img/ser_person01.png);
}

.p-serFlow__Block:nth-of-type(2)::before {
  bottom: -75px;
  left: 280px;
  background-image: url(../../../service/img/ser_person02.png);
  background-size: 70%;
}

.p-serFlow__Block:nth-of-type(3)::before {
  bottom: -55px;
  left: 283px;
  background-size: 70%;
  background-image: url(../../../service/img/ser_person03.png);
}

.p-serFlow__Block dl {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  align-items: center;
}

.p-serFlow__Block dl dt {
  position: relative;
  width: 410px;
  min-width: 410px;
  padding: 0 0 0 60px;
  font-weight: bold;
  font-size: 22px;
  color: #323232;
  line-height: 1.5;
}

.p-serFlow__Block dl dd {
  padding: 0 0 0 40px;
  border-left: 2px dashed #fa3e3e;
  font-size: 16px;
}

.p-serFlow__Block dl dd p {
  line-height: 1.7;
  color: #323232;
  margin: 0 auto 10px;
}

.p-serFlow__Block dl dd a {
  position: relative;
  color: #fa3e3e;
  transition: 0.4s ease;
}

.p-serFlow__Block dl dd a:hover {
  opacity: 0.7;
}

.p-serFlow__Block dl dd a.p-ser--arrow::after {
  content: '';
  position: absolute;
  right: -20px;
  top: 0;
  width: 16px;
  height: 16px;
  background-image: url(../../../service/img/ser_arrow02.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: auto;
}

.p-serFlow__Block:nth-of-type(1) dt:after {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 45px;
  height: 50px;
  background-image: url(../../../service/img/ser_num01.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

.p-serFlow__Block:nth-of-type(2) dt:after {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 45px;
  height: 50px;
  background-image: url(../../../service/img/ser_num02.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

.p-serFlow__Block:nth-of-type(3) {
  margin: 0 auto 85px;
}

.p-serFlow__Block:nth-of-type(3) dt:after {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 45px;
  height: 50px;
  background-image: url(../../../service/img/ser_num03.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

.p-serNet__image {
  width: 100%;
  margin: 0 auto 50px;
}

.p-serNet__image img {
  width: 100%;
  max-width: 100%;
}

.p-serContact {
  width: 800px;
  margin: auto;
  padding: 15px 50px 50px;
  border: 2px solid #fa3e3e;
  border-radius: 20px;
}

.p-serContact__title {
  position: relative;
  text-align: center;
  top: -40px;
  width: 580px;
  margin: auto;
  background-color: #fff;
  color: #323232;
  font-size: 24px;
  font-weight: bold;
}

.p-serContact__title::before {
  position: absolute;
  top: 0;
  left: 20px;
  width: 2px;
  height: 50px;
  -webkit-transform: rotate(-20deg);
  -ms-transform: rotate(-20deg);
  transform: rotate(-20deg);
  background-color: #fa3e3e;
  content: '';
}

.p-serContact__title::after {
  position: absolute;
  top: 0;
  right: 20px;
  width: 2px;
  height: 50px;
  -webkit-transform: rotate(20deg);
  -ms-transform: rotate(20deg);
  transform: rotate(20deg);
  background-color: #fa3e3e;
  content: '';
}

.p-serContact__tel {
  border-right: 1px solid #fa3e3e;
  margin: 0 28px 0 0;
  padding: 0 45px 0 0;
  white-space: nowrap;
}

.p-serContact__tel a {
  font-weight: bold;
  font-family: "Roboto", sans-serif;
  font-size: 38px;
  color: #fa3e3e;
}

.p-serContact__inner {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  align-items: center;
  justify-content: center;
}

.p-serContact__text p {
  text-align: center;
  font-size: 12px;
  line-height: 1.5;
  color: #323232;
}

.p-serContact__button a {
  transition: 0.4s ease;
}

.p-serContact__button a:hover {
  opacity: 0.7;
}

.p-serTitle {
  text-align: left;
  border-bottom: 2px solid #fbc6c3;
  position: relative;
}

.p-serTitle::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: -3px;
  width: 200px;
  height: 3px;
  background-color: #fa3e3e;
}

.p-serRead {
  margin: 0 0 40px;
}

.p-serRead p {
  text-align: left;
}

.p-serClass {
  background-color: #fbf8f4;
  border-radius: 10px;
  margin: 0 0 18px;
  padding: 28px 30px 30px;
}

.p-serClass .c-smallTile {
  font-size: 20px;
  margin: 0 0 10px;
}

.p-serClass .c-smallTile span {
  font-size: 12px;
  color: #323232;
  margin: 0 0 0 10px;
}

.p-serClass dl {
  position: relative;
  padding: 0 0 0 30px;
  margin: 0 0 5px;
}

.p-serClass dl::before {
  content: '1';
  position: absolute;
  text-align: center;
  left: 0;
  top: 2px;
  font-family: "Roboto", sans-serif;
  color: #fff;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  padding: 0 0 3px;
  background-color: #fa3e3e;
}

.p-serClass dl:nth-of-type(2) {
  margin: 0 0 20px;
}

.p-serClass dl:nth-of-type(2)::before {
  content: '2';
}

.p-serClass li {
  position: relative;
  padding: 0 0 0 15px;
  color: #323232;
  margin: 0 0 5px;
}

.p-serClass li:last-of-type {
  margin: 0;
}

.p-serClass li::before {
  content: '';
  position: absolute;
  top: 9px;
  left: 0;
  background-color: #fa3e3e;
  width: 5px;
  height: 5px;
  border-radius: 50%;
}

.p-serClass__title {
  position: relative;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  position: relative;
  width: 185px;
  margin: 0 auto 5px;
}

.p-serClass__title::before {
  position: absolute;
  top: 1px;
  left: 20px;
  width: 2px;
  height: 30px;
  transform: rotate(-20deg);
  background-color: #fa3e3e;
  content: '';
}

.p-serClass__title::after {
  position: absolute;
  top: 1px;
  right: 20px;
  width: 2px;
  height: 30px;
  transform: rotate(20deg);
  background-color: #fa3e3e;
  content: '';
}

.p-serSecond__contact {
  margin: -35px 0 0;
}

.p-serSecond__contact .c-basicButton {
  display: block;
  text-align: center;
  font-size: 20px;
  padding: 15px 20px 20px;
}

.p-serSecond__contact .c-basicButton .c-icon-mail {
  font-size: 22px;
  margin: 0 12px 0 0;
}

.p-serSecond__contact .c-basicButton::after {
  display: none;
}

.p-serSecond__contactTitle {
  position: relative;
  font-size: 24px;
  color: #323232;
  text-align: center;
  width: 440px;
  margin: 0 auto 30px;
}

.p-serSecond__contactTitle::before {
  -webkit-transform: rotate(-20deg);
  -ms-transform: rotate(-20deg);
  position: absolute;
  top: 0;
  left: 29px;
  width: 2px;
  height: 75px;
  transform: rotate(-20deg);
  background-color: #fa3e3e;
  content: '';
}

.p-serSecond__contactTitle::after {
  -webkit-transform: rotate(20deg);
  -ms-transform: rotate(20deg);
  position: absolute;
  top: 0;
  right: 29px;
  width: 2px;
  height: 75px;
  transform: rotate(20deg);
  background-color: #fa3e3e;
  content: '';
}

@media screen and (max-width: 768px) {
  .p-sectionService {
    padding: 20px 0 60px;
  }
  .p-serList__item {
    width: 33.33%;
    margin: 0 5% 0 0;
  }
  .p-serList {
    margin: 0 auto 30px;
  }
  .p-serList__img {
    text-align: center;
  }
  .p-serList__img img {
    width: 90%;
    max-width: 100%;
  }
  .p-serList__item:nth-of-type(1)::after {
    top: 30%;
    right: -15px;
    width: 11px;
    height: 12px;
    background-size: contain;
  }
  .p-serList__item:nth-of-type(2)::after {
    top: 26%;
    right: -15px;
    width: 11px;
    height: 12px;
    background-size: contain;
  }
  .p-serList__text p {
    white-space: nowrap;
    font-size: 13px;
  }
  .p-serFlow__Block {
    padding: 70px 20px 20px;
  }
  .p-serFlow__Block::before {
    display: none;
  }
  .p-serFlow__Block dl {
    flex-direction: column;
  }
  .p-serFlow__Block dl dt {
    text-align: center;
    width: 100%;
    min-width: auto;
    font-size: 20px;
    padding: 0 0 10px;
  }
  .p-serFlow__Block dl dd {
    font-size: 15px;
    padding: 15px 0 0;
    border-left: none;
    border-top: 1px dashed #fa3e3e;
  }
  .p-serFlow__Block:nth-of-type(1) dt:after {
    top: -45px;
    left: 50%;
    transform: translateX(-50%);
    background-size: contain;
    width: 37px;
    height: 37px;
  }
  .p-serFlow__Block:nth-of-type(2) dt:after {
    top: -45px;
    left: 50%;
    transform: translateX(-50%);
    background-size: contain;
    width: 37px;
    height: 37px;
  }
  .p-serFlow__Block:nth-of-type(3) dt:after {
    top: -45px;
    left: 50%;
    transform: translateX(-50%);
    background-size: contain;
    width: 37px;
    height: 37px;
  }
  .p-serFlow__Block:nth-of-type(3) {
    margin: 0 auto 30px;
  }
  .p-serContact {
    width: 100%;
    padding: 0;
    border: none;
  }
  .p-serContact__title {
    font-size: 17px;
    width: 100%;
    top: auto;
    margin: 0 0 10px;
  }
  .p-serContact__title::before {
    left: 35px;
    top: 5px;
    height: 40px;
  }
  .p-serContact__title::after {
    right: 40px;
    top: 5px;
    height: 40px;
  }
  .p-serContact__inner {
    flex-direction: column;
  }
  .p-serContact__tel {
    margin: 0 0 15px;
    padding: 0;
    border: none;
    width: 100%;
    height: 80px;
    text-align: center;
  }
  .p-serContact__tel a {
    width: 100%;
    height: 100%;
    background-color: #fa3e3e;
    font-size: 28px;
    display: block;
    color: #fff;
    border-radius: 10px;
    padding: 10px 0 0;
  }
  .p-serContact__tel img {
    width: 100%;
    max-width: 100%;
  }
  .p-serContact__tel .p-compContact__text {
    margin: -25px 0 0;
  }
  .p-serContact__button img {
    width: 100%;
    max-width: 100%;
  }
  .p-serContact__text {
    display: none;
  }
  .p-serGrid__second {
    margin: 0 0 0;
  }
  .p-serGrid__second .c-commonGrid__secondRight {
    width: 100%;
  }
  .p-serClass li {
    font-size: 15px;
  }
  .p-serClass dl {
    font-size: 15px;
  }
  .p-serSecond__contact .c-basicButton {
    font-size: 18px;
    padding: 16px 20px 20px;
  }
  .p-serSecond__contactTitle {
    font-size: 17px;
    width: 100%;
  }
  .p-serSecond__contactTitle::before {
    top: 5px;
    left: 30px;
    height: 40px;
  }
  .p-serSecond__contactTitle::after {
    top: 5px;
    right: 35px;
    height: 40px;
  }
  .p-serNet__image {
    margin: 0 auto 30px;
  }
  .p-serClass__title::before {
    height: 25px;
  }
  .p-serClass__title::after {
    height: 25px;
  }
}

.p-recbanner {
  text-align: center;
  margin: 0 auto 60px;
}

.p-recbanner a:hover {
  opacity: 0.7;
}

.p-recCareer {
  margin: 0 0 25px;
  width: 100%;
  font-weight: bold;
}

.p-recCareer .p-recCareer__history {
  color: #fff;
  padding: 10px 20px 6px;
  border-radius: 6px 6px 0 0;
  background-color: #fa3e3e;
  font-size: 18px;
}

.p-recCareer .p-recCareer__name {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #fbf8f4;
  border-radius: 0 0 6px 6px;
  padding: 22px 20px 25px;
  font-size: 26px;
  line-height: 1;
}

.p-recGrid .c-smallTile {
  font-size: 20px;
  padding: 0 0 0 20px;
  position: relative;
}

.p-recGrid .c-smallTile::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 15px;
  height: 1px;
  background-color: #fa3e3e;
}

.p-recList {
  width: 800px;
  margin: 0 auto;
  padding: 0;
}

.p-recList > li {
  position: relative;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  width: auto;
  padding: 27px 50px;
  border-radius: 10px;
  background-color: #fff;
  font-size: 20px;
  line-height: 1.5;
  text-align: left;
  text-overflow: ellipsis;
  flex: 1;
}

.p-recList > li p {
  margin: 10px 0 0;
}

.p-recList > li:nth-of-type(even) {
  background-color: #fbf8f4;
}

.p-recList > li:nth-of-type(1) {
  z-index: 20;
}

.p-recList > li:nth-of-type(2) {
  z-index: 19;
}

.p-recList > li:nth-of-type(3) {
  z-index: 18;
}

.p-recList > li:nth-of-type(4) {
  z-index: 17;
}

.p-recList > li:nth-of-type(5) {
  z-index: 16;
}

.p-recList > li:nth-of-type(6) {
  z-index: 15;
}

.p-recList > li:nth-of-type(7) {
  z-index: 14;
}

.p-recList > li:nth-of-type(8) {
  z-index: 13;
}

.p-recList > li:nth-of-type(9) {
  z-index: 12;
}

.p-recList > li:nth-of-type(10) {
  z-index: 11;
}

.p-recList > li:nth-of-type(11) {
  z-index: 10;
}

.p-recList > li:nth-of-type(12) {
  z-index: 9;
}

.p-recList > li:nth-of-type(13) {
  z-index: 8;
}

.p-recList > li:nth-of-type(14) {
  z-index: 7;
}

.p-recList > li:nth-of-type(15) {
  z-index: 6;
}

.p-recList > li:nth-of-type(16) {
  z-index: 5;
}

.p-recList > li:nth-of-type(17) {
  z-index: 4;
}

.p-recList > li:nth-of-type(18) {
  z-index: 3;
}

.p-recList > li:nth-of-type(19) {
  z-index: 2;
}

.p-recList > li:nth-of-type(20) {
  z-index: 1;
}

.p-recList > li .p-recList__num {
  display: block;
  min-width: 50px;
  height: 50px;
  margin: 0 40px 5px 0;
  padding: 3px 0 0;
  font-family: "Roboto", sans-serif;
  font-size: 30px;
  text-align: center;
  border-radius: 50%;
  background-color: #fa3e3e;
  color: #fff;
}

.p-recList > li .p-recList__num::after,
.p-recList > li .p-recList__num::before {
  position: absolute;
  top: 30px;
  display: block;
  left: 74px;
  z-index: -1;
  /* width: 100%; */
  height: 100%;
  width: 3px;
  content: '';
  background-color: #fa3e3e;
}

.p-recList > li:last-child .p-recList__num::after,
.p-recList > li:last-child .p-recList__num::before {
  width: 0%;
  margin-right: 50%;
}

.p-recButton {
  margin: 50px auto 0;
}

.p-recTable {
  margin: auto;
}

@media screen and (max-width: 768px) {
  .p-recbanner {
    margin: 0 auto 40px;
  }
  .p-recbanner img {
    width: 100%;
    max-width: 100%;
  }
  .p-recGrid .c-smallTile {
    font-size: 18px;
  }
  .p-recCareer .p-recCareer__name {
    margin: 0;
    padding: 18px 20px 15px;
    font-size: 22px;
  }
  .p-recGrid.c-commonGrid__second .c-commonGrid__text.p-recGrid__text {
    margin: 0 0 20px;
  }
  .p-recButton {
    margin: 30px auto 0;
  }
  .p-recTable {
    margin: auto;
  }
  .p-recList {
    width: 100%;
  }
  .p-recList > li .p-recList__num {
    min-width: 25px;
    height: 25px;
    font-size: 12px;
    margin: 0 15px 5px 0;
    padding: 4px 0 0;
  }
  .p-recList > li {
    padding: 10px;
    font-size: 15px;
  }
  .p-recList > li p {
    margin: 3px 0 0;
  }
  .p-recList > li .p-recList__num::after,
  .p-recList > li .p-recList__num::before {
    top: 10px;
    left: 21px;
  }
}

.p-sectionNews .c-tabContent__content {
  padding: 40px 0;
}

.p-newsDetail__Block {
  width: 800px;
  margin: auto;
}

.p-newsDetail__BlockTitle {
  line-height: 1.5;
  color: #fa3e3e;
  font-weight: bold;
  font-size: 28px;
  margin: 0 0 25px;
  word-wrap: break-word;
  word-break: break-all;
}

.p-newsDetail__info {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0 0 20px;
}

.p-newsDetail__date {
  font-size: 16px;
  color: #646464;
  margin: 0 20px 0 0;
}

.p-newsDetail__content {
  padding: 40px 0 0;
  border-top: 1px dashed #c8c8c8;
  word-wrap: break-word;
  word-break: break-all;
}

.p-newsDetail__content h2 {
  margin: 45px 0;
  color: #fa3e3e;
  font-weight: bold;
  line-height: 1.5;
  padding: 0 0 0 15px;
  font-size: 20px;
  border-left: 5px dotted #fa3e3e;
}

.p-newsDetail__content p {
  font-size: 16px;
  line-height: 1.7;
  color: #323232;
}

.p-newsDetail__content ol {
  list-style: decimal;
}

.p-newsDetail__content ol li {
  font-size: 16px;
  line-height: 1.7;
  color: #323232;
  margin: 0 0 0 20px;
  display: list-item;
}

.p-newsDetail__content .alignright {
  float: right;
  clear: both;
}

.p-newsDetail__content .alignleft {
  float: left;
  clear: both;
}

.p-newsDetail__content .aligncenter {
  clear: both;
  text-align: center;
  margin: auto;
  display: block;
}

.p-newsDetail__content blockquote {
  font-size: 14px;
}

.p-newsDetail__content img {
  max-width: 800px;
}

.p-newsDetail__content ul {
  list-style: disc;
}

.p-newsDetail__content ul li {
  font-size: 16px;
  line-height: 1.7;
  color: #323232;
  display: list-item;
  margin: 0 0 0 20px;
}

.p-newsDetail__content strong {
  font-weight: bold;
}

.p-newsDetail__content em {
  font-style: italic;
}

.p-newsDetail__content a {
  color: #fa3e3e;
  transition: 0.4s ease;
  border-bottom: 1px solid #fa3e3e;
  padding: 0 0 1px;
}

.p-newsDetail__content a:hover {
  opacity: 0.7;
  border-bottom: 1px solid transparent;
}

.p-newsDetail__content a.p-newsPdf {
  position: relative;
  display: inline-block;
  color: #fa3e3e;
  font-size: 16px;
  border: 1px solid #fa3e3e;
  border-radius: 6px;
  margin: 50px 0 0;
  padding: 25px 25px 25px 65px;
  transition: 0.4s ease;
}

.p-newsDetail__content a.p-newsPdf:hover {
  background-color: #fa3e3e;
  color: #fff;
}

.p-newsDetail__content a.p-newsPdf:hover::before {
  background-image: url(../img/pdf_icon02.png);
}

.p-newsDetail__content a.p-newsPdf::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 20px;
  width: 32px;
  height: 34px;
  transition: 0.4s ease;
  transform: translateY(-50%);
  background-image: url(../img/pdf_icon01.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

@media screen and (max-width: 768px) {
  .p-sectionNews .c-tabContent__content {
    padding: 15px 0 0;
  }
  .p-newsDetail__Block {
    width: 100%;
  }
  .p-newsDetail__BlockTitle {
    font-size: 22px;
    margin: 0 0 10px;
  }
  .p-newsDetail__info {
    margin: 0 0 15px;
  }
  .p-newsDetail__date {
    font-size: 14px;
  }
  .p-newsDetail__content {
    padding: 25px 0 0;
  }
  .p-newsDetail__content h2 {
    font-size: 18px;
    margin: 33px 0;
    border-left: 4px dotted #fa3e3e;
  }
  .p-newsDetail__content p {
    font-size: 15px;
  }
  .p-newsDetail__content img {
    width: 100%;
    max-width: 100%;
    height: 100%;
  }
  .p-newsDetail__content a.p-newsPdf {
    padding: 20px 20px 20px 50px;
    font-size: 15px;
    width: 82%;
    display: block;
    margin: 40px auto 0;
  }
  .p-newsDetail__content a.p-newsPdf::before {
    left: 15px;
    width: 25px;
  }
}

.p-contRead {
  margin: 0 0 35px;
}

.p-contRead p {
  text-align: center;
  font-size: 16px;
  line-height: 1.7;
  color: #323232;
}

.p-contRead__contact {
  width: 360px;
  margin: 0 auto 60px;
  height: 110px;
  padding: 12px 0 0;
  border-top: 2px solid #fa3e3e;
  border-bottom: 2px solid #fa3e3e;
}

.p-contRead__contactTel {
  text-align: center;
}

.p-contRead__contactTel a {
  font-weight: bold;
  font-size: 38px;
  color: #fa3e3e;
}

.p-contRead__contactTel a span {
  font-family: "Roboto", sans-serif;
}

.p-contRead__contactTel__text {
  margin: -5px 0 0;
}

.p-contRead__contactTel__text p {
  text-align: center;
  color: #323232;
  font-size: 12px;
}

.p-contForm__Block {
  width: 800px;
  margin: 0 auto 40px;
  border-top: 1px dashed #c8c8c8;
}

.p-contForm__Block dl {
  padding: 20px 20px 20px 0;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  align-items: flex-start;
  border-bottom: 1px dashed #c8c8c8;
}

.p-contForm__Block dt {
  position: relative;
  padding: 0 0 0 15px;
  width: 245px;
  min-width: 245px;
  font-weight: bold;
}

.p-contForm__Block dt span {
  padding: 0 0 1px;
  font-size: 12px;
  color: #fa3e3e;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  width: 41px;
  height: 23px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  border: 1px solid #fa3e3e;
}

.p-contForm__Block dd {
  width: 100%;
  font-size: 16px;
  padding: 0 0 0 30px;
}

.p-contForm__Block dd.p-contFlex input {
  width: 180px;
}

.p-contForm__Block dd.p-contFlex input:nth-of-type(1) {
  margin: 0 9px 0 0;
}

.p-contForm__Block select {
  width: 100%;
  height: 40px;
  padding: 0 12px;
  background-color: #f0f0f0;
  appearance: none;
  border-radius: 4px;
  border: none;
  color: #323232;
}

.p-contForm__Block input {
  width: 100%;
  height: 40px;
  padding: 0 12px;
  background-color: #f0f0f0;
  appearance: none;
  border-radius: 4px;
  border: none;
  color: #323232;
}

.p-contForm__Block input::placeholder,
.p-contForm__Block textarea::placeholder {
  font-size: 16px;
  color: #8c8c8c;
}

.p-contForm__Block textarea {
  font-size: 16px;
  width: 100%;
  min-height: 200px;
  padding: 15px;
  background-color: #f0f0f0;
  appearance: none;
  border-radius: 4px;
  border: none;
  color: #323232;
}

input:-internal-autofill-selected {
  background-color: #f0f0f0;
}

input.p-contInput__tel {
  width: 180px;
  margin: 0 0 10px 12px;
}

.p-contSelect__wrap {
  position: relative;
  width: 100%;
}

.p-contSelect__wrap::after {
  content: '';
  position: absolute;
  top: 35%;
  right: 14px;
  width: 7px;
  height: 7px;
  border-top: 1px solid #747474;
  border-right: 1px solid #747474;
  transform: rotate(135deg) translateY(-50%);
}

.p-contButton__wrap {
  cursor: pointer;
  width: 300px;
  height: 60px;
  margin: auto;
  position: relative;
}

.p-contButton__wrap::after {
  position: absolute;
  display: block;
  right: 18px;
  top: 50%;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
  margin-right: 0;
  margin-left: auto;
  background-image: url(../img/button_icon02.svg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  content: "";
}

.p-contButton__wrap .p-contButton {
  cursor: pointer;
  width: 300px;
}

.p-confRead {
  margin: 0 0 55px;
}

.p-confButton__wrap {
  width: 300px;
  height: 60px;
  margin: auto;
  text-align: center;
  position: relative;
}

.p-confButton__wrap::after {
  display: block;
  position: absolute;
  top: 50%;
  right: auto;
  left: 110px;
  width: 25px;
  height: 19px;
  margin-right: 0;
  margin-left: auto;
  transform: translateY(-50%);
  background-image: url(../img/mail_icon.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  content: "";
}

.p-confButton__wrap .p-confButton {
  width: 100%;
  padding: 15px 20px 20px 55px;
  display: block;
  text-align: center;
  font-size: 20px;
}

.p-confWrap {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  width: 800px;
  margin: auto;
}

.p-confWrap .p-confButton__wrap {
  margin: 0;
  right: 250px;
}

.p-confButton__prevWrap {
  position: relative;
  width: 140px;
  height: 60px;
  margin: 0 auto 0 0;
  border-radius: 6px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #fa3e3e;
}

.p-confButton__prevWrap:hover {
  opacity: 0.7;
}

.p-confButton__prevWrap::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 45px;
  transform: translateY(-50%) rotate(-180deg);
  background-image: url(../img/button_icon01.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  width: 16px;
  height: 16px;
}

.p-confButton__prevWrap .p-confButton__prev {
  width: 100%;
  height: 100%;
  display: block;
  font-size: 16px;
  color: #fa3e3e;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 0 0 0 30px;
}

.p-confButton__prevWrap .p-confButton__prev:hover {
  opacity: 0.7;
}

.p-confForm .p-contForm__Block dl {
  padding: 32px 20px 24px 0;
}

.p-thanChara {
  margin: auto;
  width: 280px;
}

.p-thanChara img {
  width: 100%;
  max-width: 100%;
}

.p-contInput__addressSearch {
  width: 80px;
  height: auto;
  font-size: 14px;
  padding: 6px 5px 5px;
  margin: 0 0 0 10px;
  display: inline-block;
  text-align: center;
}

.p-contInput__addressSearch::after {
  display: none;
}

.p-contSelect__wrap02 {
  width: 180px;
}

.p-contSelect__wrap02 select {
  width: 100%;
}

select::-ms-expand {
  display: none;
}

.p-contNote {
  width: 800px;
  margin: 40px auto 0;
  font-size: 16px;
  text-align: left;
}

@media screen and (max-width: 768px) {
  .p-contRead {
    margin: 0 0 20px;
  }
  .p-contRead p {
    text-align: left;
    font-size: 15px;
  }
  .p-contRead__contact {
    width: 100%;
    height: auto;
    border: none;
    margin: 0 auto 30px;
  }
  .p-contRead__contact .c-icon-tel {
    left: -8px;
  }
  .p-contRead__contact a {
    color: #fff;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
    margin: 0 auto 10px;
    height: 60px;
    font-size: 26px;
    background-color: #fa3e3e;
    border-radius: 6px;
  }
  .p-contRead__contactTel__text p {
    color: #646464;
  }
  .p-contForm__Block {
    border-top: 1px dashed #c8c8c8;
    width: 100%;
  }
  .p-contForm__Block dl {
    flex-direction: column;
    border-bottom: 1px dashed #c8c8c8;
    padding: 13px 15px 14px 0;
  }
  .p-contForm__Block dt {
    width: 100%;
    min-width: auto;
    font-size: 15px;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    margin: 0 0 0;
    padding: 0;
  }
  .p-contForm__Block dt span {
    margin: 10px 0 0 8px;
    position: static;
  }
  .p-contForm__Block dd {
    padding: 0;
  }
  .p-contForm__Block dd.p-contFlex input {
    width: 100%;
  }
  .p-contForm__Block dd.p-contFlex input:nth-of-type(1) {
    margin: 0 0 10px;
  }
  .p-contForm__Block input {
    height: 50px;
  }
  .p-contForm__Block input.p-contInput__tel {
    width: 91%;
  }
  .p-contForm__Block select {
    height: 50px;
  }
  .p-contForm__Block textarea {
    min-height: 150px;
  }
  .p-contButton__wrap {
    height: 50px;
    width: 90%;
  }
  .p-contButton__wrap::after {
    transform: translateY(0);
  }
  .p-contButton__wrap .p-contButton {
    width: 100%;
  }
  .p-confRead {
    margin: 0 0 35px;
  }
  .p-confForm .p-contForm__Block {
    margin: 0 auto 30px;
  }
  .p-confForm .p-contForm__Block dl {
    padding: 15px 20px 10px 0;
  }
  .p-confWrap {
    width: auto;
    margin: auto;
    flex-direction: column;
  }
  .p-confWrap .p-confButton__wrap {
    order: 1;
    width: 90%;
    right: auto;
    margin: 0 auto 15px;
    height: 50px;
  }
  .p-confWrap .p-confButton__wrap .p-confButton {
    height: 50px;
    padding: 10px 20px 20px 55px;
  }
  .p-confWrap .p-confButton__prevWrap {
    order: 2;
  }
  .p-confButton__prevWrap {
    width: 64%;
    height: 45px;
    margin: auto;
  }
  .p-confButton__prevWrap .p-confButton__prev {
    width: 100%;
    height: 100%;
  }
  .p-confButton__prevWrap .p-confButton__prev::after {
    left: 85px;
  }
  .p-contInput__addressSearch {
    margin: 0 auto 10px;
    width: 30%;
  }
  .p-contSelect__wrap02 {
    width: 100%;
  }
  .p-contSelect__wrap02 select {
    width: 100%;
  }
  .p-contNote {
    width: 100%;
    font-size: 14px;
  }
}

.p-404 {
  padding: 120px 0;
  text-align: center;
}
