
.p-phi__read{
  position: relative;
  max-width: 730px;
  padding: 20px 0;
  margin: 0 auto 70px;
  &::before{
    content: '';
    position: absolute;
    bottom: -25px;
    right: -25px;
    box-sizing: border-box;
    width: 25px;
    height: 25px;
    border: 25px solid transparent;
    border-left: 25px solid #fa3e3e;
    transform: rotate(45deg);
  }
  &::after{
    content: '';
    position: absolute;
    top: -25px;
    left: -25px;
    box-sizing: border-box;
    width: 25px;
    height: 25px;
    border: 25px solid transparent;
    border-left: 25px solid #fa3e3e;
    transform: rotate(-135deg);
  }
  // p{
    line-height: 1.5;
    text-align: center;
    font-weight: bold;
    color: #fa3e3e;
    font-size: 28px;
  // }
}

.p-phiHistory__grid{
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  align-items: flex-start;
  margin: 0 0 80px;
  &Left{
    width: 302px;
    min-width: 302px;
  }
  &Right{
    flex: 1;
    margin: 0 0 0 40px;
  }
}
.p-phiHistory__image{
  margin: 0 0 15px;
  img{
    width: 100%;
    max-width: 100%;
  }
}
.p-phiHistory__textName{
    line-height: 1.5;
    color: #323232;
    font-size: 16px;
    margin: 0 0 10px;
    font-weight: bold;

  span.p-phiHistory__textName--large{
    font-size: 24px;
    color: #fa3e3e;
    font-weight: bold;
  }
  span.p-phiHistory__textName--small{
    font-size: 16px;
    color: #fa3e3e;
    font-weight: bold;
  }
}

.p-phiHistory__textPost{
  color: #323232;
  font-size: 14px;
  line-height: 1.5;
}
.p-phiHistory__read{
  color: #323232;
  p{
    line-height: 1.7;
    margin: 0 0 50px;
  }
  dl{
    line-height: 1.7;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    align-items: flex-start;
    padding: 15px 0 15px 25px;
    // margin: 0 0 30px;
    &:nth-of-type(odd){
      background-color: #fbf8f4;
    }
    &:last-of-type{
      margin: 0;
    }
  }
  dt{
    font-weight: bold;
    margin: 0 40px 0 0;
    width: 170px;
    min-width: 170px;
  }
}
.p-phiShirota__Block{
  padding: 60px 0;
  background-color: #fbf8f4;
}
.p-phiShirota__Block__text{
  color: #323232;
  line-height: 1.7;
  text-align: center;
  margin: 0 0 25px;
}
.p-phiShirota__Block__image{
  width: 560px;
  margin: auto;
  img{
    width: 100%;
    max-width: 100%;
  }
}

.p-phiAbout{
  padding: 55px 0 75px;
}
.p-phiAbout__grid{
  .c-basicButton{
    font-size: 18px;
  }
}


@include sp {
  .p-phi__read{
    margin: 0 0 45px;
    padding: 0;
    width: 100%;
    max-width: none;
    // p{
      font-size: 22px;
    // }
  }
  .p-phi__read::before{
    bottom: -20px;
    right: -20px;
    width: 20px;
    height: 20px;
    border: 20px solid transparent;
    border-left: 20px solid #fa3e3e;
  }
  .p-phi__read::after{
    top: -20px;
    left: -20px;
    width: 20px;
    height: 20px;
    border: 20px solid transparent;
    border-left: 20px solid #fa3e3e;
  }

  .p-phiHistory__grid{
    margin: 0 0 42px;
    flex-direction: column;
    &Left{
      width: 100%;
      min-width: auo;
    }
    &Right{
      margin: 0;
    }
  }
  .p-phiHistory__text{
    margin: 0 0 30px;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
  }
  .p-phiHistory__textName{
    font-size: 15px;
    margin: 0 45px 0 0;
  }
  .p-phiHistory__textName span.p-phiHistory__textName--large{
    font-size: 20px;
  }
  .p-phiHistory__textName span.p-phiHistory__textName--small{
    font-size: 15px;
  }
  .p-phiHistory__read{
    p{
      margin: 0 0 40px;
      font-size: 15px;
    }
    dl{
      flex-direction: column;
      padding: 15px;
      &:last-of-type{
        // margin: 0 0 0 15px;
      }
    }
  }
  .p-phiShirota__Block{
    padding: 30px 17px;
  }
  .p-phiShirota__Block__image{
    width: 90%;
  }

  .p-phiAbout__text{
    margin: 0 0 25px;
    p{
      font-size: 15px;
    }
  }
  .p-phiAbout__image{
    img{
      width: 100%;
      max-width: 100%;
    }
  }
  .p-phiAbout__button{
    width: 78.66%;
    height: 50px;
  }

  .p-phiAbout__grid{
    .c-basicButton{
      font-size: 17px;
    }
  }

}
