.c-bannerArea {
  width: 100%;
  overflow: hidden;
  padding: 67px 0 85px;
  &__slider {
    position: relative;
  }
  &__banner {
    transition: 100ms ease-out;
    &:hover {
      opacity: 0.7;
    }
    img {
      width: 100%;
    }
  }
}

@include sp {
  .c-bannerArea {
    padding: 40px 0 65px;
    .swiper-container {
      width: calc(100% - 60px);
    }
  }
}