.p-cenList{
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  flex-wrap: wrap;
}
.p-cenList__item{
  width: 32.1%;
  margin: 0 18px 18px 0;
  &:nth-of-type(3n){
    margin: 0 0 18px;
  }
  a{
    position: relative;
    border: 2px solid #fa3e3e;
    width: 100%;
    height: 50px;
    font-size: 20px;
    color: #fa3e3e;
    text-align: center;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    transition: 0.4s ease;
    &:hover{
      opacity: 0.7;
    }
    &::after{
      position: absolute;
      top: 15px;
      width: 16px;
      height: 16px;
      right: 20px;
      transform: rotate(90deg);
      background-image: url(../img/button_icon01.svg);
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;
      content: '';
    }
  }
}

.p-cenCat__list{
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  position: absolute;
  top: 11px;
  left: 11px;
}
.p-cenCat__item{
  color: #fff;
  margin: 0 4px 0 0;
  padding: 2px 13px;
  font-size: 12px;
  border-radius: 10px;
  &:last-of-type{
    margin: 0;
  }
}
.p-cenCat__item--childcare{
  background-color: #ffab3b;
}
.p-cenCat__item--salon{
  background-color: #ff7575;
}

.p-sectionCenAria{
  background-color: #fbf8f4;
}
.p-cenAria__Block{
  margin: 0 0 50px;
  &:last-of-type{
    margin: 0;
  }


}
.p-cenAria__title{
  font-size: 28px;
  line-height: 1.5;
  color: #323232;
  margin: 0 0 40px;
  padding: 0 0 15px;
  border-bottom: 4px dotted #fa3e3e;
  a{
    display: block;
  }
}

.p-cenAriaList{
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  flex-wrap: wrap;
}
.p-cenAriaList__item{
  width: 31.75%;
  position: relative;
  background-color: #fff;
  margin: 0 23px 23px 0;
  border-radius: 10px 10px 0 0;
  overflow: hidden;
  &:nth-of-type(3n){
    margin: 0 0 23px;
  }
}

.p-cenAriaList{
  img{
    width: 100%;
    max-width: 100%;
  }
}

.p-cenAriaList__text{
  padding: 20px;
  h4{
    margin: 0 0 15px;
    line-height: 1.5;
    font-size: 20px;
    color: #fa3e3e;
  }
  p{
    line-height: 1.5;
    font-size: 14px;
    color: #323232;
  }
}
.p-cenAriaList__button{
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  padding: 0 20px 20px;
  a{
    font-size: 14px;
    width: 50%;
    height: 34px;
    &:nth-of-type(1){
      margin: 0 11px 0 0;
    }
  }
}


.p-cenAriaList__button--map{
  &::after{
    display: block;
    width: 12px;
    height: 16px;
    margin-right: 0;
    margin-left: auto;
    background-image: url(../img/button_icon04.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    content: "";
  }

}
.p-cenAriaList__button--detail{
  &::after{
    display: block;
    width: 14px;
    height: 14px;
    margin-right: 0;
    margin-left: auto;
    background-image: url(../img/button_icon05.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    content: "";
  }
}
.p-cenSalon__buttonWrap{
  & > p{
    line-height: 1.7;
    color: #323232;
    margin: 0 0 20px;
  }
  .c-basicButton{
    width: 300px;
    margin: 0;
  }
}

@include sp {

  .p-cenList{
    // flex-direction: column;
  }
  .p-cenAria__Block{
    border-top: 2px dotted #fa3e3e;
    margin: 0;
    &:last-of-type{
      .p-cenAria__title{
        border-bottom: 2px dotted #fa3e3e;
      }
      .p-cenAriaList{
        border-top: none;
      }
    }
  }

  .p-cenAria__title{
    font-size: 22px;
    margin: -50px 0 0;
    padding: 65px 0 15px;
    // border-top: 2px dotted #fa3e3e;
    // border-bottom: 2px dotted #fa3e3e;
    border-top: none;
    border-bottom: none;
    a{
      position: relative;
      &::before{

      }
      &::after{

      }
    }
  }
  .p-cenList__item:nth-of-type(3n){
    margin: 0 auto 9px;
  }
  .p-cenList__item{
    width: 48%;
    margin: 0 2% 9px 0;
    &:nth-of-type(even){
      margin: 0 0 9px;
    }
    &:nth-of-type(3n){
      margin: 0 2% 9px 0;
    }
    &:last-of-type{
      margin: 0 0 9px;
    }
    a{
      height: auto;
      padding: 10px 17px;
      font-size: 14px;
      &::after{
        right: 8px;
        width: 13px;
        // top: 50%;
        // transform: rotate(90deg) translateY(-50%);
      }
    }
  }

  .p-cenAriaList{
    display: none;
    flex-direction: column;
  }
  .p-cenAriaList{
    border-top: 2px dotted #fa3e3e;
    padding: 20px 0 0;
    margin: 0 0 35px;
  }
  .p-cenAriaList__item{
    width: 100%;
    border-radius: 20px 20px 0 0;
    margin: 0 0 20px;
  }
  .p-cenAriaList__item:last-of-type{
    margin: 0;
  }
  .p-cenAriaList__image{
    img{
      width: 100%;
      max-width: 100%;
    }
  }
  .p-cenAriaList__text{
    h4{
      margin: 0 0 10px;
      font-size: 18px;
    }
  }
  .p-cenAriaList__button{
    a{
      height: 40px;
    }
  }
  .p-cenSalon__buttonWrap{
    display: none;
    & > p{
      font-size: 15px;
      margin: 20px 0 20px;
    }
    .p-cenSalon__button{
      margin: 20px 0 0;
      height: 50px;
    }
  }

}
