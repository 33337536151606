.c-section {
  padding: 90px 0 100px;
  &--pt1 {
    padding: 100px 0 100px;
  }
  &--pt2 {
    padding: 120px 0 100px
  }
  &--pt3 {
    padding: 60px 0;
  }
}


.c-sectionUnder{
  padding: 20px 0 100px;
}
.c-sectionMilky{
  background-color: #fbf8f4;
}

.c-commonRead{
  margin: 0 0 50px;
  p{
    text-align: center;
    z-index: 16;
    line-height: 1.7;
    color: #323232;
  }
}

@include sp {
  .c-section {
    padding: 60px 0;
    &--pt2 {
      padding: 60px 0;
    }
  }

  .c-commonRead{
    margin: 0 0 30px;
    p{
      font-size: 15px;
    }
  }
  .c-sectionUnder{
    padding: 20px 0 60px;
  }
}
