.c-icon-bottle {
  position: relative;
  display: inline-block;
  font-size: 1em;
  font-weight: normal;
  line-height: inherit;
  &:after {
    content: '\E801';
    display: inline-block;
    font-family: 'fontello';
  }
}

.c-icon-handshake {
  position: relative;
  display: inline-block;
  font-size: 1em;
  font-weight: normal;
  line-height: inherit;
  &:after {
    content: '\E803';
    display: inline-block;
    font-family: 'fontello';
  }
}

.c-icon-tel {
  position: relative;
  display: inline-block;
  font-size: 1em;
  font-weight: normal;
  line-height: inherit;
  &:after {
    content: '\E80B';
    font-family: 'fontello';
    display: inline-block;
  }
}
.c-icon-tel2 {
  position: relative;
  display: inline-block;
  font-size: 1em;
  font-weight: normal;
  line-height: inherit;
  &:after {
    content: '\E802';
    font-family: 'fontello';
    display: inline-block;
  }
}

.c-icon-building {
  position: relative;
  display: inline-block;
  font-size: 1em;
  font-weight: normal;
  line-height: inherit;
  &:after {
    content: '\E804';
    font-family: 'fontello';
    display: inline-block;
  }
}

.c-icon-comment {
  position: relative;
  display: inline-block;
  font-size: 1em;
  font-weight: normal;
  line-height: inherit;
  &:after {
    content: '\E805';
    font-family: 'fontello';
    display: inline-block;
  }
}
.c-icon-mail {
  position: relative;
  display: inline-block;
  font-size: 1em;
  font-weight: normal;
  line-height: inherit;
  &:after {
    content: '\E807';
    font-family: 'fontello';
    display: inline-block;
  }
}