.p-contRead{
  margin: 0 0 35px;
  p{
    text-align: center;
    font-size: 16px;
    line-height: 1.7;
    color: #323232;
  }
}

.p-contRead__contact{
  width: 360px;
  margin: 0 auto 60px;
  height: 110px;
  padding: 12px 0 0;
  border-top: 2px solid #fa3e3e;
  border-bottom: 2px solid #fa3e3e;
}
.p-contRead__contactTel{
  text-align: center;
  a{
    font-weight: bold;
    font-size: 38px;
    color: #fa3e3e;
    span{
      font-family: $font-en;
    }
  }
}
.p-contRead__contactTel__text{
  margin: -5px 0 0;
  p{
    text-align: center;
    color: #323232;
    font-size: 12px;
  }

}


  .p-contForm__Block{
    width: 800px;
    margin: 0 auto 40px;
    border-top: 1px dashed #c8c8c8;
    dl{
      padding: 20px 20px 20px 0;
      display: -webkit-flex;
      display: -ms-flex;
      display: flex;
      align-items: flex-start;
      border-bottom: 1px dashed #c8c8c8;
    }
    dt{
      position: relative;
      padding: 0 0 0 15px;
      width: 245px;
      min-width: 245px;
      font-weight: bold;

      span{
        padding: 0 0 1px;
        font-size: 12px;
        color: #fa3e3e;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
        width: 41px;
        height: 23px;
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 2px;
        border: 1px solid #fa3e3e;
      }
    }
    dd{
      width: 100%;
      font-size: 16px;
      padding: 0 0 0 30px;

      &.p-contFlex{
        input{
          width: 180px;

          &:nth-of-type(1){
            margin: 0 9px 0 0;
          }
        }
      }
    }

    select{
      width: 100%;
      height: 40px;
      padding: 0 12px;
      background-color: #f0f0f0;
      appearance: none;
      border-radius: 4px;
      border: none;
      color: #323232;

      // option{
      //   &:checked{
      //     color: #8c8c8c;
      //   }
      // }
    }
    option{
      // color: #8c8c8c;
    }
    input{
      width: 100%;
      height: 40px;
      padding: 0 12px;
      background-color: #f0f0f0;
      appearance: none;
      border-radius: 4px;
      border: none;
      color: #323232;
    }
    input::placeholder,
    textarea::placeholder{
      font-size: 16px;
      color: #8c8c8c;
    }

    textarea{
      font-size: 16px;
      width: 100%;
      min-height: 200px;
      padding: 15px;
      background-color: #f0f0f0;
      appearance: none;
      border-radius: 4px;
      border: none;
      color: #323232;
    }
  }
  input:-internal-autofill-selected{
    background-color: #f0f0f0;
  }

  input.p-contInput__tel{
    width: 180px;
    margin: 0 0 10px 12px;
  }

  .p-contSelect__wrap{
    position: relative;
    width: 100%;
    &::after{
      content: '';
      position: absolute;
      top: 35%;
      // transform: translateY(-50%);
      right: 14px;
      width: 7px;
      height: 7px;
      border-top: 1px solid #747474;
      border-right: 1px solid #747474;
      transform: rotate(135deg) translateY(-50%);
    }
  }


  .p-contButton__wrap{
    cursor: pointer;
    width: 300px;
    height: 60px;
    margin: auto;
    position: relative;
    &::after{
      position: absolute;
      display: block;
      right: 18px;
      top: 50%;
      transform: translateY(-50%);
      width: 16px;
      height: 16px;
      margin-right: 0;
      margin-left: auto;
      background-image: url(../img/button_icon02.svg);
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;
      content: "";
    }
    .p-contButton{
      cursor: pointer;
      width: 300px;
    }
  }

.p-confRead{
  margin: 0 0 55px;
}


.p-confButton__wrap{
  width: 300px;
  height: 60px;
  margin: auto;
  text-align: center;
  position: relative;
  &::after{
    display: block;
    position: absolute;
    top: 50%;
    right: auto;
    left: 110px;
    width: 25px;
    height: 19px;
    margin-right: 0;
    margin-left: auto;
    transform: translateY(-50%);
    background-image: url(../img/mail_icon.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    content: "";
  }
  .p-confButton{
    width: 100%;
    padding: 15px 20px 20px 55px;
    display: block;
    text-align: center;
    font-size: 20px;
  }
}

.p-confWrap{
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  width: 800px;
  margin: auto;

  .p-confButton__wrap{
    margin: 0;
    right: 250px;
  }
}
.p-confButton__prevWrap{
  position: relative;
  width: 140px;
  height: 60px;
  margin: 0 auto 0 0;
  border-radius: 6px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #fa3e3e;
  &:hover{
    opacity: 0.7;
  }
  &::after{
    content: '';
    position: absolute;
    top: 50%;
    left: 45px;
    transform: translateY(-50%) rotate(-180deg);
    background-image: url(../img/button_icon01.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    width: 16px;
    height: 16px;

  }
  .p-confButton__prev{
    width: 100%;
    height: 100%;
    display: block;
    font-size: 16px;
    color: #fa3e3e;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 0 0 0 30px;
    &:hover{
      opacity: 0.7;
    }

  }
}


.p-confForm{
  .p-contForm__Block{
    dl{
      padding: 32px 20px 24px 0;

    }
  }
}

.p-thanChara{
  margin: auto;
  width: 280px;
  img{
    width: 100%;
    max-width: 100%;
  }
}

.p-contInput__addressSearch{
  width: 80px;
  height: auto;
  font-size: 14px;
  padding: 6px 5px 5px;
  margin: 0 0 0 10px;
  display: inline-block;
  text-align: center;
  &::after{
    display: none;
  }
}

.p-contSelect__wrap02{
  width: 180px;
  select{
    width: 100%;
  }
}


select::-ms-expand {
    display: none;
}

.p-contNote{
  width: 800px;
  margin: 40px auto 0;
  font-size: 16px;
  text-align: left;
}

@include sp {

  .p-contRead{
    margin: 0 0 20px;
    p{
      text-align: left;
      font-size: 15px;
    }
  }

  .p-contRead__contact{
    width: 100%;
    height: auto;
    border: none;
    margin: 0 auto 30px;
    .c-icon-tel{
      left: -8px;
    }
    a{
      color: #fff;
      display: -webkit-flex;
      display: -ms-flex;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 90%;
      margin: 0 auto 10px;
      height: 60px;
      font-size: 26px;
      background-color: #fa3e3e;;
      border-radius: 6px;
    }
  }

  .p-contRead__contactTel__text{
    p{
      color: #646464;
    }
  }

  .p-contForm__Block{
    border-top: 1px dashed #c8c8c8;
    width: 100%;
    dl{
      flex-direction: column;
      border-bottom: 1px dashed #c8c8c8;
      padding: 13px 15px 14px 0;
    }
    dt{
      width: 100%;
      min-width: auto;
      font-size: 15px;
      display: -webkit-flex;
      display: -ms-flex;
      display: flex;
      margin: 0 0 0;
      padding: 0;
      span{
        margin: 10px 0 0 8px;
        position: static;
      }
    }
    dd{
      padding: 0;
      &.p-contFlex{
        input{
          width: 100%;

          &:nth-of-type(1){
            margin: 0 0 10px;
          }
        }

      }
    }

    input{
      height: 50px;
    }
    input.p-contInput__tel{
      width: 91%;
    }
    select{
      height: 50px;
    }
    textarea{
      min-height: 150px;
    }
  }
  .p-contButton__wrap{
    height: 50px;
    width: 90%;
    &::after{
      transform: translateY(0);
    }
    .p-contButton{
      width: 100%;
    }
  }


  .p-confRead{
    margin: 0 0 35px;
  }
  .p-confForm .p-contForm__Block{
    margin: 0 auto 30px;
  }
  .p-confForm .p-contForm__Block dl{
    padding: 15px 20px 10px 0;
  }

  .p-confWrap{
    width: auto;
    margin: auto;
    flex-direction: column;
    .p-confButton__wrap{
      order: 1;
      width: 90%;
      right: auto;
      margin: 0 auto 15px;
      height: 50px;
      .p-confButton{
        height: 50px;
        padding: 10px 20px 20px 55px;
      }
    }
    .p-confButton__prevWrap{
      order: 2;
    }
  }

  .p-confButton__prevWrap{
    width: 64%;
    height: 45px;
    margin: auto;
    .p-confButton__prev{
      width: 100%;
      height: 100%;
      &::after{
        left: 85px;
      }
    }
  }

  .p-contInput__addressSearch{
    margin: 0 auto 10px;
    width: 30%;
  }

  .p-contSelect__wrap02{
    width: 100%;
    select{
      width: 100%;
    }
  }

  .p-contNote{
    width: 100%;
    font-size: 14px;
  }
}
