.p-mv {
  position: relative;
  &__slider {
    position: relative;
  }
  &__slide {
    width: 75%;
    a {
      display: block;
      width: 100%;
      overflow: hidden;
      img {
        width: 100%;
        transition: 500ms ease-out;
      }
      &:hover img {
        transform: scale(1.05);
        opacity: 0.8;
      }
    }
  }
  &__prev {
    left: 9%;
  }
  &__next {
    right: 9%;
  }
}


.p-topNews {
  background-image: url('../img/top_news_bg.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  &__button {
    text-align: center;
    margin: 30px auto 0;
  }
}

.p-topAbout {
  background-color: #fbf8f4;
  &__image {
    display: block;
    position: relative;
    width: 980px;
    img {
      width: 100%;
    }
  }
  &__charactor01 {
    position: absolute;
    top: -123px;
    left: 79px;
  }
  &__charactor02 {
    position: absolute;
    right: 70px;
    bottom: -15px;
  }
  &__charactor03 {
    display: none;
  }
  &__charactor04 {
    display: none;
  }
  &__content {
    position: relative;
    margin: -70px auto 0;
    padding: 45px 50px 30px;
    width: 800px;
    background-color: #fff;
    border-radius: 10px;
  }
  &__text {
    text-align: center;
    line-height: 1.75;
  }
  &__buttons {
    display: flex;
    flex-wrap: wrap;
    margin: 30px 0 0;
  }
  &__button {
    margin: 0;
    margin-right: 20px;
    margin-bottom: 20px;
    width: 220px;
    height: 60px;
    &:nth-child(3n) {
      margin-right: 0;
    }
  }
}

.p-topProduct {
  background-image: url('../img/top_product_bg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  &__text {
    text-align: center;
    line-height: 1.75;
  }
  &__image {
    margin: 0 0 0;
    img {
      width: 100%;
    }
  }
  &__buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 50px 0 0;
  }
  &__button {
    margin: 0;
    margin-right: 20px;
    width: 280px;
    height: 60px;
    &:last-child {
      margin-right: 0;
    }
  }
}

.p-topRecruit {
  background-color: #fbf8f4;
  &__grid {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }
  &__gridLeft {
    width: 360px;
    min-width: 360px;
  }
  &__gridRight {
    flex: 1;
    margin-left: 50px;
  }
  &__text {
    width: 350px;
    line-height: 1.75;
  }
  &__buttons {
    margin-top: 35px;
  }
  &__button {
    margin: 0;
    margin-bottom: 20px;
    width: 280px;
    height: 60px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.p-topContact {
  background-image: url('../img/top_contact_bg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;

  .c-largeTitle {
    position: relative;
    &__icon,
    &__main,
    &__sub {
      color: #fff;
    }
    &:before,
    &:after {
      content: "";
      display: block;
      position: absolute;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center center;
    }
    &:before {
      top: 25px;
      left: 245px;
      width: 75px;
      height: 102px;
      background-image: url('../img/top_contact_title_img01.png');
    }
    &:after {
      top: 15px;
      left: 660px;
      width: 68px;
      height: 102px;
      background-image: url('../img/top_contact_title_img02.png');
    }
  }

  &__text {
    color: #fff;
    text-align: center;
  }

  &__buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
  }

  &__button {
    display: block;
    margin-right: 25px;
    width: 360px;
    height: 90px;
    &:last-child {
      margin-right: 0;
    }
    &--tel {
      position: relative;
      padding: 20px 30px;
      color: #fff;
      &:before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: calc(100% - 12px);
        height: 100%;
        border-top: 2px solid #fff;
        border-bottom: 2px solid #fff;
      }
      &:after {
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: 100%;
        height: calc(100% - 12px);
        border-left: 2px solid #fff;
        border-right: 2px solid #fff;
      }
      .p-topContact__button__large {
        display: flex;
        align-items: center;
        font-size: 36px;
        font-weight: bold;
        vertical-align: middle;
        line-height: 1;
        font-family: $font-en;
        i {
          margin-right: 10px;
          font-size: 26px;
        }
      }
      .p-topContact__button__small {
        display: block;
        text-align: center;
        font-size: 12px;
      }
    }

    &--form {
      display: flex;
      justify-content: center;
      align-items: center;
      color: $color-primary;
      font-weight: bold;
      font-size: 24px;
      border-radius: 6px;
      background-color: #fff;
      i {
        margin-right: 10px;
        font-size: 26px;
        color: $color-primary;
      }
    }
  }
}

@include sp {
  .p-mv {
    &__prev {
      left: 0;
    }
    &__next {
      right: 0;
    }
  }
  .p-topNews {
    background-image: url('../img/top_news_bg_sp.jpg');
    &__button {
      margin-top: 20px;
    }
  }

  .p-topAbout {
    width: 100%;
    padding: 60px 0 95px;
    &__image {
      width: 100%;
    }
    .c-largeTitle {
      position: relative;
    }
    &__charactor01 {
      display: none;
    }
    &__charactor02 {
      width: 115px;
      left: 50%;
      bottom: -40px;
      transform: translate(-50%);
      img {
        width: 100%;
      }
    }
    &__charactor03,
    &__charactor04 {
      display: block;
      position: absolute;
      img {
        width: 100%;
      }
    }
    &__buttons{
      margin: 15px 0 0;
    }
    &__charactor03 {
      width: 65px;
      bottom: 0;
      left: 0;
    }
    &__charactor04 {
      width: 48px;
      bottom: 0;
      right: 6px;
    }
    &__content {
      margin-top: -25px;
      padding: 15px 20px 50px;
      width: calc(100% - 20px);
    }
    &__text {
      text-align: left;
    }
    &__button {
      width: 100%;
      height: 50px;
      margin: 0 0 10px;
    }
  }

  .p-topProduct {
    &__image {
      max-width: 289px;
      margin-left: auto;
      margin: auto;
      img {
        width: 100%;
      }
    }
    &__buttons {
      display: block;
      margin-top: 30px;
    }
    &__button {
      margin: 0 auto 10px;
      max-width: 295px;
      &:last-child {
        margin: 0 auto;
      }
    }
  }

  .p-topRecruit {
    &__imageSp {
      margin: 0 0 25px;
      width: calc(100% + 20px);
      img {
        width: 100%;
      }
    }
    &__grid {
      display: block;
    }
    &__gridLeft {
      width: 100%;
      min-width: 0;
    }
    &__gridRight {
      display: none;
    }
    &__text {
      width: 100%;
    }
    &__buttons {
      display: block;
      margin-top: 25px;
    }
    &__button {
      width: auto;
      max-width: 295px;
      height: 50px;
      margin: 0 0 10px;
      &:last-child {
        margin: 0;
      }
    }
  }
  .p-topContact {
    .c-largeTitle {
      &:before {
        top: 15px;
        left: 0;
        width: 61px;
        height: 80px;
      }
      &:after {
        top: 8px;
        right: 0;
        left: auto;
        width: 55px;
        height: 83px;
      }
    }
    &__text {
      text-align: left;
    }
    &__buttons {
      display: block;
      margin-top: 25px;
    }
    &__button {
      width: 100%;
      height: auto;
      min-height: 80px;
      &--tel,
      &--form {
        background-color: #fff;
        color: $color-primary;
        text-align: center;
        border-radius: 6px;
        padding: 20px 15px;
      }
      &--tel {
        margin-bottom: 15px;
        &:before,
        &:after {
          content: none;
        }
      }
      &--tel &__large {
        justify-content: center;
        font-size: 28px;
        i {
          font-size: 24px;
        }
      }
      &--tel &__small {
        font-size: 12px;
      }
      &--form {
        font-size: 21px;
        i {
          font-size: 23px;
        }
      }
    }
  }
}
