.p-recbanner{
  text-align: center;
  margin: 0 auto 60px;
  a{
    &:hover{
      opacity: 0.7;
    }
  }
}

.p-recCareer{
  margin: 0 0 25px;
  width: 100%;
  font-weight: bold;
  .p-recCareer__history{
    color: #fff;
    padding: 10px 20px 6px;
    border-radius: 6px 6px 0 0;
    background-color: #fa3e3e;
    font-size: 18px;
  }
  .p-recCareer__name{
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: #fbf8f4;
    border-radius: 0 0 6px 6px;
    padding: 22px 20px 25px;
    // border-right: 1px solid #fa3e3e;
    font-size: 26px;
    line-height: 1;
  }

}


.p-recGrid{
  .c-smallTile{
    font-size: 20px;
    padding: 0 0 0 20px;
    position: relative;
    &::before{
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 15px;
      height: 1px;
      background-color: #fa3e3e;;
    }
  }
}



.p-recList {
	width: 800px;
	margin: 0 auto;
	padding: 0;
}
.p-recList > li {
	position: relative;
	display: -webkit-flex;
	display: -ms-flex;
	display: flex;
	width: auto;
	padding: 27px 50px;
  border-radius: 10px;
  background-color: #fff;
  font-size: 20px;
  line-height: 1.5;
  text-align: left;
	text-overflow: ellipsis;
   flex: 1;
   p{
     margin: 10px 0 0;
   }
   &:nth-of-type(even){
     background-color: #fbf8f4;
   }
   &:nth-of-type(1){
     z-index: 20;
   }
   &:nth-of-type(2){
     z-index: 19;
   }
   &:nth-of-type(3){
     z-index: 18;
   }
   &:nth-of-type(4){
     z-index: 17;
   }
   &:nth-of-type(5){
     z-index: 16;
   }
   &:nth-of-type(6){
     z-index: 15;
   }
   &:nth-of-type(7){
     z-index: 14;
   }
   &:nth-of-type(8){
     z-index: 13;
   }
   &:nth-of-type(9){
     z-index: 12;
   }
   &:nth-of-type(10){
     z-index: 11;
   }
   &:nth-of-type(11){
     z-index: 10;
   }
   &:nth-of-type(12){
     z-index: 9;
   }
   &:nth-of-type(13){
     z-index: 8;
   }
   &:nth-of-type(14){
     z-index: 7;
   }
   &:nth-of-type(15){
     z-index: 6;
   }
   &:nth-of-type(16){
     z-index: 5;
   }
   &:nth-of-type(17){
     z-index: 4;
   }
   &:nth-of-type(18){
     z-index: 3;
   }
   &:nth-of-type(19){
     z-index: 2;
   }
   &:nth-of-type(20){
     z-index: 1;
   }


}
.p-recList > li .p-recList__num {
	display: block;
  min-width: 50px;
  height: 50px;
	margin: 0 40px 5px 0;
  padding: 3px 0 0;
  font-family: "Roboto", sans-serif;
  font-size: 30px;
  text-align: center;
	border-radius: 50%;
	background-color: $color-primary;
  color: #fff;
}
.p-recList > li .p-recList__num::after,
.p-recList > li .p-recList__num::before {
	position: absolute;
	top: 30px;
	display: block;
  left: 74px;
  z-index: -1;
	/* width: 100%; */
  height: 100%;
	width: 3px;
	content: '';
	background-color: $color-primary;
}

.p-recList > li:last-child .p-recList__num::after,
.p-recList > li:last-child .p-recList__num::before {
	width: 0%;
	margin-right: 50%;
}




.p-recButton{
  margin: 50px auto 0;
}
.p-recTable{
  margin: auto;
}

@include sp {

  .p-recbanner{
    margin: 0 auto 40px;
    img{
      width: 100%;
      max-width: 100%;
    }
  }
  .p-recGrid .c-smallTile{
    font-size: 18px;
  }

  .p-recCareer{
    .p-recCareer__name{
      margin: 0;
      padding: 18px 20px 15px;
      font-size: 22px;
    }
  }

  .p-recGrid.c-commonGrid__second{
    .c-commonGrid__text.p-recGrid__text{
      margin: 0 0 20px;
    }
  }



  .p-recButton{
    margin: 30px auto 0;
  }
  .p-recTable{
    margin: auto;
  }




  .p-recList{
    width: 100%;
  }

  .p-recList > li .p-recList__num{
    min-width: 25px;
    height: 25px;
    // min-width: auto;
    font-size: 12px;
    margin: 0 15px 5px 0;
    padding: 4px 0 0;
  }
  .p-recList > li{
    padding: 10px;
    font-size: 15px;
    p{
      margin: 3px 0 0;
    }
  }
  .p-recList > li .p-recList__num::after,
  .p-recList > li .p-recList__num::before{
    top: 10px;
    left: 21px;
  }


}
