.c-pagiNation__Block{
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 50px 0 0;


  ul{
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
  }
  li{
    margin: 0 10px 10px 0;
    &:last-of-type{
      margin: 0 0 10px;
    }
    a,
    span{
      border: 1px solid #fa3e3e;
      border-radius: 6px;
      width: 40px;
      height: 40px;
      display: -webkit-flex;
      display: -ms-flex;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fa3e3e;
      transition: 0.4s ease;
      &:hover{
        background-color: #fa3e3e;
        color: #fff;
      }
      &.current{
        background-color: #fa3e3e;
        color: #fff;
      }
    }
  }


  // .prev{
  //   margin: 0 10px 10px 0;
  // }
  // .next{
  //   margin: 0 0 10px 10px;
  // }
  a.prev,
  a.next{
    text-indent: -9999px;
    position: relative;
    border: 1px solid #fa3e3e;
    border-radius: 6px;
    border-radius: 6px;
    width: 40px;
    height: 40px;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fa3e3e;
    transition: 0.4s ease;
    &:hover{
      background-color: #fa3e3e;
    }
    &:hover::after{
      border-top: 2px solid #fff;
      border-right: 2px solid #fff;
    }
  }
  a.prev::after{
    transition: 0.4s ease;
    position: absolute;
    top: 15px;
    left: 16px;
    width: 9px;
    height: 9px;
    transform: rotate(225deg);
    border-top: 2px solid #fa3e3e;
    border-right: 2px solid #fa3e3e;
    content: '';
  }
  a.next::after{
    transition: 0.4s ease;
    position: absolute;
    top: 15px;
    left: 13px;
    width: 9px;
    height: 9px;
    transform: rotate(45deg);
    border-top: 2px solid #fa3e3e;
    border-right: 2px solid #fa3e3e;
    content: '';
  }
}



@include sp {
  .c-pagiNation__Block{
    margin: 30px 0 0;
    .prev{
      margin: 0 8px 8px 0;
    }
    .next{
      margin: 0 0 8px 8px;
    }



    li{
      margin: 0 8px 8px 0;
      &:last-of-type{
        margin: 0 0 8px;
      }
    }
  }

}
