.c-footer {
      border-top: 1px solid #fa3e3e;
  &__content {
    position: relative;
    padding-top: 60px;
    padding-bottom: 60px;
  }
  &__logoWrap {
    display: flex;
    align-items: flex-end;
    margin-bottom: 50px;
  }
  &__logo {
    margin-right: 20px;
  }
  &__company {
    font-size: 20px;
    font-weight: bold;
  }
  &__inner {
    display: flex;
  }
  &__info {
    margin-right: 80px;
    width: 266px;
    font-size: 14px;
  }
  &__address {
    margin-bottom: 15px;
  }
  &__tel {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    color: $color-primary;
    font-family: $font-en;
    font-size: 30px;
    line-height: 1;
    i {
      margin-right: 10px;
      color: $color-primary;
      font-size: 26px;
    }
  }
  &__sns {
    display: flex;
    margin-top: 30px;
  }
  &__snsLink {
    display: block;
    margin-right: 18px;
    width: 36px;
    min-width: 36px;
    transition: 100ms ease-out;
    &:hover {
      opacity: 0.7;
    }
    img {
      width: 100%;
    }
  }
  &__links {
    width: 300px;
    margin-right: 38px;
    &:last-child {
      margin-right: 0;
    }
  }
  &__largeLink {
    display: block;
    position: relative;
    font-size: 16px;
    line-height: 1;
    padding: 14px 3px;
    border-bottom: 1px solid #dcdcdc;
    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      right: 3px;
      transform: translateY(-50%);
      width: 16px;
      height: 16px;
      background-image: url('../img/button_icon01.svg');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
    }
    &:hover {
      background-color: rgba(200, 200, 200, 0.2);
    }
    &--disable:hover {
      background-color: transparent;
    }
    &--disable:after {
      content: none;
    }

  }
  &__smallLinks {
    margin-top: 5px;
    margin-bottom: 8px;
  }
  &__smallLink {
    display: block;
    position: relative;
    padding: 10px 17px;
    font-size: 14px;
    line-height: 1;
    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      left: 4px;
      transform: translateY(-50%);
      width: 5px;
      height: 8px;
      background-image: url('../img/button_icon03.svg');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
    }
    &:hover {
      background-color: rgba(200, 200, 200, 0.2);
    }
  }
  &__copyright {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    color: #fff;
    font-size: 10px;
    font-family: $font-en;
    background-color: $color-primary;
  }
  &__toTop {
    display: none;
    position: fixed;
    right: 100px;
    z-index: 19;
    bottom: 120px;
    a{
      position: absolute;
      width: 60px;
      height: 60px;
      display: block;
    }
    &.js-fixstop{

      // bottom: 120px;
    }
  }
}

@include sp {
  .c-footer {
    &__content {
      position: relative;
      padding-top: 50px;
      padding-bottom: 30px;
    }
    &__logoWrap {
      display: block;
      margin-bottom: 25px;
    }
    &__logo {
      width: 197px;
      margin: 0 auto 10px;
      img {
        width: 100%;
      }
    }
    &__company {
      font-size: 18px;
      text-align: center;
    }
    &__address {
      font-size: 14px;
      text-align: center;
    }
    &__inner {
      display: block;
    }
    &__info {
      padding-bottom: 30px;
      width: 100%;
    }
    &__tel {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto 10px;
      max-width: 295px;
      height: 60px;
      border-radius: 6px;
      color: #fff !important;
      font-size: 26px;
      background-color: $color-primary;
      span {
        font-size: 26px;
        color: #fff !important;
      }
      i {
        color: #fff;
        font-size: 21px;
      }
    }
    &__time {
      font-size: 12px;
      text-align: center;
    }
    &__links {
      margin-left: -20px;
      width: calc(100% + 40px);
    }
    &__largeLink {
      padding: 15px 20px;
      height: 50px;
      font-size: 15px;
      &:after {
        right: 20px;
      }
      &:first-child {
        border-top: 1px solid #dcdcdc;
      }
      &--disable {
        &:last-of-type {
          border-bottom: none;
        }
        &:before,
        &:after {
          content: "";
          display: block;
          position: absolute;
          top: 50%;
          right: 23px;
          width: 15px;
          height: 1px;
          background-color: $color-primary;
          transition: 300ms ease-out;
        }
        &.is-active {
          border-bottom: 1px solid #dcdcdc;
        }
        &:after {
          transform: rotate(90deg);
        }
        &.is-active:after {
          transform: rotate(0deg);
        }
      }
    }
    &__links:last-child &__largeLink--disable {
      border-bottom: 1px solid #dcdcdc;
    }
    &__smallLinks {
      display: none;
      margin: 0;
      padding: 0 20px;
      background-color: #f5f5f5;
      border-bottom: 1px solid #dcdcdc;
      &:last-child {
        border-bottom: none;
      }
    }
    &__smallLink {
      display: flex;
      align-items: center;
      background-color: transparent;
      height: 50px;
      border-bottom: 1px solid #dcdcdc;
      &:last-child {
        border-bottom: none;
      }
    }
    &__sns {
      justify-content: space-around;
      margin-top: 28px;
    }
    &__snsLink {
      margin: 0;
      width: 40px;
      img {
        width: 100%;
      }
    }
    &__copyright {
      height: 50px;
      text-align: center;
      font-size: 10px;
      color: #fff !important;
    }
    &__toTop {
      position: fixed;
      right: 5px;
      top: auto;
      bottom: 150px;
      width: 50px;
      a{
        width: 50px;
        height: 50px;
      }
      img {
        width: 100%;
      }

      &.js-fixstop{
        position: absolute;
        top: -25px;
        bottom: auto;
      }
    }
  }
}
