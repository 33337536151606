.c-textButton {
  display: inline-flex;
  align-items: center;
  font-size: 16px;
  color: $color-primary;
  vertical-align: middle;
  font-weight: 500;
  padding: 4px;
  border-radius: 4px;
  transition: 100ms ease-out;
  &:hover {
    background-color: rgba(250, 62, 62, 0.1);
  }
  &:after {
    content: '';
    display: inline-block;
    margin-left: 8px;
    width: 16px;
    height: 16px;
    background-image: url('../img/button_icon01.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
  }
}

.c-basicButton {
  display: flex;
  align-items: center;
  position: relative;
  padding: 20px;
  color: #fff;
  margin: auto;
  width: 360px;
  height: 60px;
  border-radius: 10px;
  background-color: $color-primary;
  font-weight: 700;
  transition: 100ms ease-out;
  &:hover {
    opacity: 0.8;
  }
  &:after {
    content: "";
    display: block;
    margin-left: auto;
    margin-right: 0;
    width: 16px;
    height: 16px;
    background-image: url('../img/button_icon02.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
  }
}
.c-basicButton--blank{
  &:after {
    content: "";
    display: block;
    margin-left: auto;
    margin-right: 0;
    width: 18px;
    height: 18px;
    background-image: url('../img/button_icon03.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
  }
}




@include sp {
  .c-textButton {
    font-size: 14px;
    &:after {
      width: 15px;
      height: 15px;
    }
  }
  .c-basicButton {
    width: 100%;
    font-size: 15px;
    border-radius: 6px;
  }
}
