// layout
$breakpoint: 768px;
$content-width: 980px;
$content-padding: 60px;

// color
$color-black: #323232;
$color-gray: #c8c8c8;
$color-primary: #fa3e3e;
$color-secondary: #ffb430;
$color-tertiary: #55ceec;
$color-link: #323232;

// font
$font-ja: "ヒラギノ角ゴシック", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", Roboto, "Droid Sans", YuGothic, "游ゴシック", Meiryo, "メイリオ", Verdana, "ＭＳ Ｐゴシック", sans-serif;
$font-en: 'Roboto', sans-serif;
$font-icon: 'fontello';
$font-text-size: 16px;
$font-sp-text-size: 15px;

$line-height: 1.5;

// breakpoint
@mixin sp {
  @media screen and (max-width: 768px) {
    @content;
  }
}
@mixin pc {
  @media screen and (min-width: 769px) {
    @content;
  }
}
