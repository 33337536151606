
.p-serList{
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin: 0 0 60px;
}
.p-serList__item{
  margin: 0 110px 0 0;
  &:last-of-type{
    margin: 0;
  }
  &:nth-of-type(1){
    position: relative;
    &::after{
      content: '';
      position: absolute;
      top: 70px;
      right: -65px;
      width: 21px;
      height: 25px;
      background-image: url(../../../service/img/ser_arrow.png);
      background-size: auto;
      background-repeat: no-repeat;
      background-position: center center;
    }
  }
  &:nth-of-type(2){
    position: relative;
    &::after{
      content: '';
      position: absolute;
      top: 70px;
      right: -65px;
      width: 21px;
      height: 25px;
      background-image: url(../../../service/img/ser_arrow.png);
      background-size: auto;
      background-repeat: no-repeat;
      background-position: center center;
    }
  }
}
.p-serList__img{
  margin: 0 auto 10px;
}
.p-serList__text{
  p{
    text-align: center;
    font-weight: bold;
    line-height: 1.7;
    color: #323232;
    font-size: 20px;
  }
}

.p-serFlow__Block{
  margin: 0 0 20px;
  padding: 40px;
  background-color: #fbf8f4;
  overflow: hidden;
  position: relative;
  &::before{
    content: '';
    position: absolute;
    bottom: 0;
    width: 200px;
    height: 200px;
    background-size: 50%;
    background-position: left top;
    background-repeat: no-repeat;
  }
  &:nth-of-type(1){
    &::before{
      left: 310px;
      bottom: -65px;
      background-size: 45%;
      background-image: url(../../../service/img/ser_person01.png);
    }
  }
  &:nth-of-type(2){
    &::before{
      bottom: -75px;
      left: 280px;
      background-image: url(../../../service/img/ser_person02.png);
      background-size: 70%;
    }
  }
  &:nth-of-type(3){
    &::before{
      bottom: -55px;
      left: 283px;
      background-size: 70%;
      background-image: url(../../../service/img/ser_person03.png);
    }
  }
  dl{
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    align-items: center;

    dt{
      position: relative;
      width: 410px;
      min-width: 410px;
      padding: 0 0 0 60px;
      font-weight: bold;
      font-size: 22px;
      color: #323232;
      line-height: 1.5;
    }
    dd{
      padding: 0 0 0 40px;
      border-left: 2px dashed #fa3e3e;
      font-size: 16px;
      p{
        line-height: 1.7;
        color: #323232;
        margin: 0 auto 10px;
      }
      a{
        position: relative;
        color: #fa3e3e;
        transition: 0.4s ease;
        &:hover{
          opacity: 0.7;
        }
      }
      a.p-ser--arrow{
        &::after{
          content: '';
          position: absolute;
          right: -20px;
          top: 0;
          width: 16px;
          height: 16px;
          background-image: url(../../../service/img/ser_arrow02.png);
          background-position: center center;
          background-repeat: no-repeat;
          background-size: auto;
        }
      }
    }
  }
  &:nth-of-type(1){
    dt{
      &:after{
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 45px;
        height: 50px;
        background-image: url(../../../service/img/ser_num01.png);
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
      }
    }

  }
  &:nth-of-type(2){
    dt{
      &:after{
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 45px;
        height: 50px;
        background-image: url(../../../service/img/ser_num02.png);
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
      }
    }

  }
  &:nth-of-type(3){
    margin: 0 auto 85px;
    dt{
      &:after{
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 45px;
        height: 50px;
        background-image: url(../../../service/img/ser_num03.png);
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
      }
    }
  }

}

.p-serNet__image{
  width: 100%;
  margin: 0 auto 50px;
  img{
    width: 100%;
    max-width: 100%;
  }
}


.p-serContact{
  width: 800px;
  margin: auto;
  padding: 15px 50px 50px;
  border: 2px solid #fa3e3e;
  border-radius: 20px;
}

.p-serContact__title{
  position: relative;
  text-align: center;
  top: -40px;
  width: 580px;
  margin: auto;
  background-color: #fff;
  color: #323232;
  font-size: 24px;
  font-weight: bold;
  &::before{
    position: absolute;
    top: 0;
    left: 20px;
    width: 2px;
    height: 50px;
    -webkit-transform: rotate(-20deg);
    -ms-transform: rotate(-20deg);
    transform: rotate(-20deg);
    background-color: #fa3e3e;
    content: '';
  }
  &::after{
    position: absolute;
    top: 0;
    right: 20px;
    width: 2px;
    height: 50px;
    -webkit-transform: rotate(20deg);
    -ms-transform: rotate(20deg);
    transform: rotate(20deg);
    background-color: #fa3e3e;
    content: '';
  }
}
.p-serContact__tel{
  border-right: 1px solid #fa3e3e;
  margin: 0 28px 0 0;
  padding: 0 45px 0 0;
  white-space: nowrap;
  a{
    font-weight: bold;
    font-family: $font-en;
    font-size: 38px;
    color: #fa3e3e;;
  }
}
.p-serContact__inner{
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  align-items: center;
  justify-content: center;
}
.p-serContact__text{
   p{
     text-align: center;
     font-size: 12px;
     line-height: 1.5;
     color: #323232;
   }
}
.p-serContact__button{
  a{
    transition: 0.4s ease;
    &:hover{
      opacity: 0.7;
    }
  }
}



.p-serTitle{
  text-align: left;
  border-bottom: 2px solid #fbc6c3;
  position: relative;
  &::before{
    content: '';
    position: absolute;
    left: 0;
    bottom: -3px;
     width: 200px;
     height: 3px;
     background-color: #fa3e3e;
  }
}
.p-serRead{
  margin: 0 0 40px;
  p{
  text-align: left;
  }
}
.p-serClass{
  background-color: #fbf8f4;
  border-radius: 10px;
  margin: 0 0 18px;
  padding: 28px 30px 30px;

  .c-smallTile{
    font-size: 20px;
    margin: 0 0 10px;
    span{
      font-size: 12px;
      color: #323232;
      margin: 0 0 0 10px;
    }
  }
  dl{
    position: relative;
    padding: 0 0 0 30px;
    margin: 0 0 5px;
    &::before{
      content: '1';
      position: absolute;
      text-align: center;
      left: 0;
      top: 2px;
      font-family: $font-en;
      color: #fff;
      border-radius: 50%;
       width: 22px;
       height: 22px;
       padding: 0 0 3px;
       background-color: #fa3e3e;
    }
    &:nth-of-type(2){
      margin: 0 0 20px;
      &::before{
        content: '2';
      }
    }
  }
  li{
    position: relative;
    padding: 0 0 0 15px;
    color: #323232;
    margin: 0 0 5px;
    &:last-of-type{
      margin: 0;
    }
    &::before{
      content: '';
      position: absolute;
      top: 9px;
      left: 0;
      background-color: #fa3e3e;
      width: 5px;
      height: 5px;
      border-radius: 50%;
    }
  }
}

.p-serClass__title{
  position: relative;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  position: relative;
  width: 185px;
  margin: 0 auto 5px;
  &::before{
    position: absolute;
    top: 1px;
    left: 20px;
    width: 2px;
    height: 30px;
    transform: rotate(-20deg);
    background-color: #fa3e3e;
    content: '';
  }
  &::after{
    position: absolute;
    top: 1px;
    right: 20px;
    width: 2px;
    height: 30px;
    transform: rotate(20deg);
    background-color: #fa3e3e;
    content: '';
  }
}



.p-serSecond__contact{
  margin: -35px 0 0;
  .c-basicButton{
    display: block;
    text-align: center;
    font-size: 20px;
    padding: 15px 20px 20px;

    .c-icon-mail{
      font-size: 22px;
      margin: 0 12px 0 0;
    }

    &::after{
      display: none;
    }
  }
}

.p-serSecond__contactTitle{
  position: relative;
  font-size: 24px;
  color: #323232;
  text-align: center;
  width: 440px;
  margin: 0 auto 30px;
    &::before {
      -webkit-transform: rotate(-20deg);
      -ms-transform: rotate(-20deg);
      position: absolute;
      top: 0;
      left: 29px;
      width: 2px;
      height: 75px;
      transform: rotate(-20deg);
      background-color: #fa3e3e;
      content: '';
  }
    &::after {
      -webkit-transform: rotate(20deg);
      -ms-transform: rotate(20deg);
      position: absolute;
      top: 0;
      right: 29px;
      width: 2px;
      height: 75px;
      transform: rotate(20deg);
      background-color: #fa3e3e;
      content: '';
  }
}

@include sp {
  .p-sectionService{
    padding: 20px 0 60px;
  }
  .p-serList__item{
    width: 33.33%;
    margin: 0 5% 0 0;
  }
  .p-serList{
    margin: 0 auto 30px;
  }
  .p-serList__img{
    text-align: center;
    img{
      width: 90%;
      max-width: 100%;
    }
  }
  .p-serList__item:nth-of-type(1)::after{
    top: 30%;
    right: -15px;
    width: 11px;
    height: 12px;
    background-size: contain;
  }
  .p-serList__item:nth-of-type(2)::after{
    top: 26%;
    right: -15px;
    width: 11px;
    height: 12px;
    background-size: contain;
  }
  .p-serList__text{
    p{
      white-space: nowrap;
      font-size: 13px;
    }
  }
  .p-serFlow__Block{
    padding: 70px 20px 20px;
    &::before{
      display: none;
    }
    dl{
      flex-direction: column;
      dt{
        text-align: center;
        width: 100%;
        min-width: auto;
        font-size: 20px;
        padding: 0 0 10px;
      }
      dd{
        font-size: 15px;
        padding: 15px 0 0;
        border-left: none;
        border-top: 1px dashed #fa3e3e;
      }
    }
  }

  .p-serFlow__Block:nth-of-type(1) dt:after{
    top: -45px;
    left: 50%;
    transform: translateX(-50%);
    background-size: contain;
    width: 37px;
    height: 37px;
  }
  .p-serFlow__Block:nth-of-type(2) dt:after{
    top: -45px;
    left: 50%;
    transform: translateX(-50%);
    background-size: contain;
    width: 37px;
    height: 37px;
  }
  .p-serFlow__Block:nth-of-type(3) dt:after{
    top: -45px;
    left: 50%;
    transform: translateX(-50%);
    background-size: contain;
    width: 37px;
    height: 37px;
  }
  .p-serFlow__Block:nth-of-type(3){
    margin: 0 auto 30px;
  }
  .p-serContact{
    width: 100%;
    padding: 0;
    border: none;
  }
  .p-serContact__title{
    font-size: 17px;
    width: 100%;
    top: auto;
    margin: 0 0 10px;
    &::before{
      left: 35px;
      top: 5px;
      height: 40px;
    }
    &::after{
      right: 40px;
      top: 5px;
      height: 40px;
    }
  }
  .p-serContact__inner{
    flex-direction: column;
  }
  .p-serContact__tel{
    margin: 0 0 15px;
    padding: 0;
    border: none;
    width: 100%;
    height: 80px;
    text-align: center;
    a{
      width: 100%;
      height: 100%;
      background-color: #fa3e3e;
      font-size: 28px;
      display: block;
      color: #fff;
      border-radius: 10px;
      padding: 10px 0 0;
    }
    img{
      width: 100%;
      max-width: 100%;
    }
    .p-compContact__text{
      margin: -25px 0 0;
    }
  }
  .p-serContact__button{
    img{
      width: 100%;
      max-width: 100%;
    }
  }
  .p-serContact__text{
    display: none;
  }

  .p-serGrid__second{
    margin: 0 0 0;
    .c-commonGrid__secondRight{
      width: 100%;
    }
  }

  .p-serClass{
    li{
      font-size: 15px;
    }
    dl{
      font-size: 15px;
    }
  }
  .p-serSecond__contact{
   .c-basicButton{
     font-size: 18px;
     padding: 16px 20px 20px;
   }
  }
  .p-serSecond__contactTitle{
    font-size: 17px;
    width: 100%;
    &::before {
    top: 5px;
    left: 30px;
    height: 40px;
    }
    &::after {
    top: 5px;
    right: 35px;
    height: 40px;
    }
  }

  .p-serNet__image{
    margin: 0 auto 30px;
  }


  .p-serClass__title{
    &::before{
      height: 25px;
    }
    &::after{
      height: 25px;
    }
  }


}
