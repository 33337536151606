.c-newsList {
  &__item {
    border-top: 2px dashed $color-gray;
    &:last-child {
      border-bottom: 2px dashed $color-gray;
    }
    a {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 17px 5px;
      transition: 100ms ease-out;
      &:hover {
        background-color: rgba(200,200,200,0.2);
      }
    }
  }
  &__meta {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__date {
    margin-right: 25px;
    min-width: 130px;
    color: #646464;
    font-size: 16px;
  }
  &__category {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 24px;
    min-width: 100px;
    height: 24px;
    color: #fff;
    font-size: 12px;
    border-radius: 12px;
    &--primary {
      background-color: $color-primary;
    }
    &--secondary {
      background-color: $color-secondary;
    }
    &--tertiary {
      background-color: $color-tertiary;
    }
  }
  &__text {
    color: $color-black;
    font-size: 16px;
    word-wrap: break-word;
    word-break: break-all;
  }
}

@include sp {
  .c-newsList {
    &__item {
      border-top: 1px dashed $color-gray;
      &:last-child {
        border-bottom: 1px dashed $color-gray;
      }
      a {
        padding: 10px 0;
        flex-wrap: wrap;
      }
    }
    &__meta {
      margin-bottom: 4px;
    }
    &__date {
      margin-right: 10px;
      font-size: 14px;
    }
    &__category {
      min-width: 100px;
      height: 20px;
      border-radius: 10px;
      font-size: 12px;
    }
    &__text {
      font-size: 14px;
      line-height: 1.5;
    }
  }
}
